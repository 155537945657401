import { apiUrl, axios } from '@/api/api.js'
import { PAGE_SIZE, DEFAULT_PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export const getUserNotifications = async (page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
    try {
        return await axios.get(`${ apiUrl }/notification`, { params: {
                'page-size': pageSize,
                page,
            }
        });
    } catch (error) {
        generateErrors(error);
    }
};

export const readUserNotification = async (data) => {
    try {
        return await axios.post(`${ apiUrl }/notification/read`, data);
    } catch (error) {
        generateErrors(error);
    }
}

export const getNotifications = async (page = 1, pageSize = PAGE_SIZE, filter = {}, sort = 'sort') => {
    try {
        return await axios.get(`${ apiUrl }/system-notification`, { params: {
                'page-size': pageSize,
                page,
                filter: JSON.stringify(filter),
                include: 'form,organization',
                sort
            }
        });
    } catch (error) {
        generateErrors(error);
    }
};

export const createNotification = async data => {
    try {
        return await axios.post(`${ apiUrl }/system-notification`, data);
    } catch (error) {
      generateErrors(error);
    }
};

export const getNotificationById = async id => {
    try {
        const result = await axios.get(`${ apiUrl }/system-notification/${ id }`);
        return result.data.data
    } catch (error) {
        generateErrors(error);
    }
};

export const patchNotification = async (notificationId, data) => {
    try {
        return await axios.patch(`${ apiUrl }/system-notification/${ notificationId }`, data);
    } catch (error) {
        generateErrors(error);
    }
};

export const deleteNotification = async (notificationId) => {
    try {
        return await axios.delete(`${ apiUrl }/system-notification/${ notificationId }`);
    } catch (error) {
        generateErrors(error);
    }
};

export const getNotificationByDistribution = async (formId) => {
    try {
        return await axios.get(`${ apiUrl }/system-notification/for/formDistribution/${ formId }`);
    } catch (error) {
        generateErrors(error);
    }
};

export const getNotificationByStartPage = async () => {
    try {
        return await axios.get(`${ apiUrl }/system-notification/for/startPage`);
    } catch (error) {
        generateErrors(error);
    }
};
