export const requestToastHandler = {
    methods: {
        $requestError(message) {
            if (!message) return;
            this.$toast.add({
                severity: 'error',
                summary: message,
                life: '3500',
            });
        },
    },
};
