export default [
    {
        path: 'list',
        name: 'periodicityList',
        component: () => import('@/pages/control/periodicity/periodicityList.vue'),
        meta: {},
    },
    {
        path: 'new',
        component: () => import('@/pages/control/periodicity/newPeriodicity.vue'),
        redirect: { path: '/control/periodicity/new/newmainparametrs' },
        meta: {},
        children: [
            {
                path: 'newmainparametrs',
                name: 'mainparametrs',
                component: () => import('@/pages/control/periodicity/mainparametrs.vue'),
                meta: {},
            }
        ],
    },
    {
        path: ':periodicityId',
        name: ':periodicityId',
        component: () => import('@/pages/control/periodicity/newPeriodicity.vue'),
        redirect: { path: '/control/periodicity/:periodicityId/mainparametrs' },
        meta: {},
        children: [
            {
                path: 'mainparametrs',
                name: '',
                component: () => import('@/pages/control/periodicity/mainparametrs.vue'),
                meta: {},
            },
            {
                path: 'periods',
                name: 'periods',
                component: () => import('@/pages/control/periodicity/editperiods.vue'),
                meta: {},
            },

        ],
    }
];
