// import { createStore } from 'vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './modules/auth';

const plugins = [];

// if (process.env.NODE_ENV === 'development') {
//   plugins.push(createLogger());
// }


Vue.use(Vuex);

const store = new Vuex.Store({
    plugins,
    state: () => ({
        rowsPerPageOptions: [20, 50, 100, 200],
        globalLoader: true,
        constructorRightMenuOpen: false,
        checkedAfterLogin: false,
        rootEmit: null,
        dataEntryFilter: null,
        aggregateFilter: null,
    }),
    mutations: {
        setGlobalLoader(state, payload) {
            state.globalLoader = payload;
        },
        changeRightMenuVisible(state, payload) {
            state.constructorRightMenuOpen = payload;
        },
        setCheckedAfterLogin(state, val) {
            state.checkedAfterLogin = val;
        },
        setRootEmit(state, payload) {
            state.rootEmit = payload
        },
        clearDataEntryFilter(state) {
            state.dataEntryFilter = null;
        },
        clearAggregateFilter(state) {
            state.aggregateFilter = null;
        },
        setDataEntryFilter(state, payload) {
            state.dataEntryFilter = payload;
        },
        setAggregateFilter(state, payload) {
            state.aggregateFilter = payload;
        },
        saveDataEntryFilter(state, payload) {
            state.dataEntryFilter = payload;
            localStorage.setItem('dataEntryFilter', JSON.stringify(payload));
        },
        saveAggregateFilter(state, payload) {
            state.aggregateFilter = payload;
            localStorage.setItem('aggregateFilter', JSON.stringify(payload));
        }
    },
    actions: {
        async initDataEntryFilter(context) {
            const filter = localStorage.getItem('dataEntryFilter');

            if (filter) {
                await context.commit('clearDataEntryFilter');
                context.commit('setDataEntryFilter', JSON.parse(filter));
            } else {
                context.commit('clearDataEntryFilter');
            }
        },

        async initAggregateFilter(context) {
            const filter = localStorage.getItem('aggregateFilter');

            if (filter) {
                await context.commit('clearAggregateFilter');
                context.commit('setAggregateFilter', JSON.parse(filter));
            } else {
                context.commit('clearAggregateFilter');
            }
        }
    },
    getters: {
        rowsPerPageOptions(state) {
            return state.rowsPerPageOptions;
        },

        constructorRightMenuOpen(state) {
            return state.constructorRightMenuOpen;
        },

        dataEntryFilter(state) {
            return state.dataEntryFilter;
        },

        aggregateFilter(state) {
            return state.aggregateFilter;
        },
    },
    modules: { auth: auth },
});

export default store;
