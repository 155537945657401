<template>
    <div class="login-body">
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel">
                <form @submit.prevent="onSubmit" :style="{ maxWidth: 270 + 'px' }" class="login-form">
                    <img class="p-mb-6" src="assets/layout/images/login-logo.svg" alt="diamond-layout" />
                    <div class="p-d-flex p-ai-center p-col-12 p-pl-0">
                        <h3 class="login_title p-text-center">Обновление пароля</h3>
                    </div>
                    <label class="p-text-left color"><b>Срок дейстия пароля истёк, необходимо изменить пароль.</b></label>
                    <div :class="{'p-error': (v$.oldPassword.$invalid && submitted)}" class="p-fluid p-d-flex p-flex-column p-ai-start p-col-12 p-pblank-0">
                        <InputText type="password" :feedback="false" name="oldPassword" v-model="oldPassword" placeholder="Старый пароль*" :class="v$.oldPassword.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.oldPassword)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                    </div>
                    <div :class="{'p-error': (v$.newPassword.$invalid && submitted)}" class="p-fluid p-d-flex p-flex-column p-ai-start p-col-12 p-pblank-0">
                        <InputText type="password" :feedback="false" name="newPassword" v-model="newPassword" @input="sameAs" placeholder="Новый пароль*" :class="v$.newPassword.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.newPassword)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                    </div>
                    <div :class="{'p-error': (v$.newPasswordConfirm.$invalid && submitted)}" class="p-fluid p-d-flex p-flex-column p-ai-start p-col-12 p-pblank-0">
                        <InputText type="password" :feedback="false" name="newPasswordConfirm" v-model="newPasswordConfirm" @input="notSameAs" placeholder="Подтверждение пароля*" :class="v$.newPasswordConfirm.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.newPasswordConfirm)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                        <small class="p-error p-text-left" v-html="changePasswordFailed" />
                    </div>
                    <div class="p-mb-0 p-pt-2">
                        <Button class="p-mb-4 p-button customPadding" label="Изменить пароль" type="submit" />
                    </div>
                    <p class="p-text-left">
                        По вопросам смены пароля <br />
                        обратитесь в службу поддержки НИИОЗМ <br />
                        по телефону: <a href="tel:84954171209" target="_blank" class="telColor">8 (495) 417-12-09 доб. 313/323</a>
                    </p>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-0 p-button-text decorateText p-py-1" @click="$router.push('/registrationUser')" label="Регистрация" />
                    </div>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-0 p-button-text p-py-1" @click="$router.push('/login')">
                            <span class="p-button-label decorateText">Войти</span>
                        </Button>
                    </div>
                </form>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import LoginImage from '@/components/loginComponents/LoginImage';
import { requestToastHandler } from '@/main/mixins';
import { changePassword } from '@/api/auth';

import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {mapGetters} from "vuex";

export default {
    name: 'passwordChange',
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        inputGroupClasses: ['p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-pblank-0', 'p-pl-0', 'p-py-2'],
        error: '',
        changePasswordFailed: '',
        submitted: false,
    }),
    validations() {
        return {
            oldPassword: {
                required: { ...required, $message: 'Поле обязательно для заполнения' },
            },
            newPassword: {
                required: { ...required, $message: 'Поле обязательно для заполнения' },
            },
            newPasswordConfirm: {
                required: { ...required, $message: 'Поле обязательно для заполнения' },
            },
        };
    },
    components: { LoginImage },
    methods: {
        async onSubmit() {
            this.submitted = true;
            if (!this.v$.$invalid && this.changePasswordFailed.length === 0) {
                try {
                    await changePassword({
                        login: this.lastLogin,
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword,
                        fingerPrint: 'string',
                    });
                    await this.$router.push('/passwordChangeConfirm');
                } catch (error) {
                    this.changePasswordFailed = error.message;
                }
            }
        },
        notSameAs() {
            clearTimeout(this.passwordTimeout);

            this.passwordTimeout = setTimeout(async () => {
                this.changePasswordFailed = '';
                if (this.newPassword?.length > 0 && this.newPassword === this.lastLogin) {
                    this.changePasswordFailed = 'Логин и пароль не должны совпадать';
                }
            }, 300);
        },
        sameAs() {
            clearTimeout(this.passwordTimeout);

            this.passwordTimeout = setTimeout(async () => {
                this.changePasswordFailed = '';
                if (this.newPassword?.length > 0 && this.newPassword !== this.newPasswordConfirm) {
                    this.changePasswordFailed = 'Пароли должны совпадать';
                }
            }, 300);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    watch: {
        oldPassword: function (newVal, oldVal) {
            newVal != oldVal ? (this.changePasswordFailed = '') : null;
        },
        newPassword: function (newVal, oldVal) {
            newVal != oldVal ? (this.changePasswordFailed = '') : null;
        },
        newPasswordConfirm: function (newVal, oldVal) {
            newVal != oldVal ? (this.changePasswordFailed = '') : null;
        },
    },
    computed: {
        ...mapGetters('auth', ['lastLogin']),
    }
};
</script>

<style lang="scss" scoped>
.login_title {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #4a4a4a;
    margin-bottom: 0;
}

.color {
    color: #4e4e4e;
}

.p-pblank-0 {
    padding-left: 0;
}

.telColor {
    color: #606060 !important;
}

.decorateText {
    text-decoration: underline;
    font-size: 12px;
    line-height: 21px;
}

.customPadding {
    padding: 10px 17px;
}

.p-fluid > small {
    margin-top: 0.25rem;
}

.login-body .login-wrapper .login-panel p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #989898;
}
</style>
