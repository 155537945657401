import { USER_MENU_PERMS, USER_PERMISSIONS_MAP } from '@/constants/common';

const {
    formWrite,
    formWriteOwn
} = USER_PERMISSIONS_MAP

export default [
    {
        path: 'list',
        name: 'formsList',
        component: () => import('@/pages/forms/formsList.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.FORMS,
        },
    },
    {
        path: 'new',
        component: () => import('@/pages/forms/editForm'),
        meta: {
            permissionAccess: [ USER_PERMISSIONS_MAP.formWrite ],
        }
    },
    {
        path: 'edit/:formId',
        name: 'edit/:formId',
        component: () => import('@/pages/forms/editForm'),
        props: true,
        meta: {
            permissionAccess: [ formWrite, formWriteOwn ],
        }
    },
    {
        path: 'constructor/:formId/:templateId',
        name: ':templateId',
        component: () => import('@/pages/forms/templateConstructor'),
        meta: {}
    }
];
