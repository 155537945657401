<template>
   <div class="loader-container">
    <div class="loader">
      <div class="bottom-section">
        <div class="bottom"></div>
        <div class="bottom"></div>
        <div class="bottom"></div>
        <div class="bottom"></div>
      </div>
      <div class="top-section">
        <div class="bottom"></div>
        <div class="bottom"></div>
        <div class="bottom"></div>
        <div class="bottom"></div>
      </div>
      <div class="middle"></div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AppGlobalLoader',
  props: {
    title: {
      type: String,
      default: 'Идет проверка авторизации... ожидайте',
    },
  },
};
</script>

<style scoped>

</style>
