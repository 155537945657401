export const AND = '$and'

export const OR = '$or'

export const NOT = '$not'

export const EQUAL = '$eq'

export const NOT_EQUAL = '$ne'

export const GREATER_THEN = '$gt'

export const GREATER_THEN_EQUAL = '$gte'

export const LESS_THEN = '$lt'

export const LESS_THAN_EQUAL = '$lte'

export const IN = '$in'

export const LIKE = '$like'

export default {
    AND,
    OR,
    NOT,
    EQUAL,
    NOT_EQUAL,
    GREATER_THEN,
    GREATER_THEN_EQUAL,
    LESS_THEN,
    LESS_THAN_EQUAL,
    IN,
    LIKE
}
