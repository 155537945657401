import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getFormAssignments({ formId, include, filter = {} }) {
  try {
      const params = {
          'page-size': PAGE_SIZE,
          page: 1,
          include: include || 'organization',
          filter: JSON.stringify(filter)
      }
    const { data } = await axios.get(`${apiUrl}/form/${formId}/relationships/form-assignments`, {
        params
    })
    return data
  } catch (error) {
    generateErrors(error);
  }
}

export async function createFormAssignments(formId, data) {
  try {
    const response = await axios.post(`${apiUrl}/form/${formId}/relationships/form-assignments`, data)
    return response.data
  } catch (error) {

    generateErrors(error);
  }
}

export async function deleteFormAssignments(formId, data) {
  console.log(' data', data);
  try {
    const response = await axios.delete(`${apiUrl}/form/${formId}/relationships/form-assignments`, { data: data })
    return response.data
  } catch (error) {
    generateErrors(error)
  }
}
