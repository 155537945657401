export const NOTIFICATION_DIALOG_MODES = {
    CREATE: {
        NAME: 'CREATE',
        TITLE: 'Добавить уведомление'
    },
    EDIT: {
        NAME: 'EDIT',
        TITLE: 'Редактировать уведомление'
    }
}

export const NOTIFICATION_PLACEMENTS = [
    { value: 'startPage', label: 'Главная страница' },
    { value: 'formDistribution', label: 'Заполнение формы' }
]

export const NOTIFICATION_STATUSES = [
    { value: 'active', label: 'активный', bgColor: '#A1E6C3' },
    { value: 'archive', label: 'архивный', bgColor: '#D7D7D7' },
]

export const USER_NOTIFICATION_TITLES = [
    { type: 'commentReplied', name: 'Получен ответ на комментарий', icon: 'custom-add-comment', format: '/dataEntry/{0}/{1}/?comments' },
    { type: 'distributionCommented', name: 'Форма прокомментирована', icon: 'custom-add-comment', format: '/dataEntry/{0}/{1}/?comments' },
    { type: 'distributionAccepted', name: 'Форма принята', icon: 'custom-distribution', format: '/dataEntry/{0}' },
    { type: 'distributionDeclined', name: 'Форма отклонена', icon: 'custom-distribution', format: '/dataEntry/{0}' },
    { type: 'distributionApproved', name: 'Форма утверждена', icon: 'custom-distribution', format: '/dataEntry/{0}' },
    { type: 'distributionSent', name: 'Форма отправлена на проверку', icon: 'custom-distribution', format: '/dataEntry/{0}' },
    { type: 'reportGenerated', name: 'Генерация отчёта выполнена успешно', icon: 'custom-report', format: '{2}' },
    { type: 'reportFailed', name: 'Произошла ошибка генерации отчёта', icon: 'custom-report', format: '{2}' },
    { type: 'licensesLoaded', name: 'Загружены лицензии', icon: 'custom-report', format: '/organizations/list' },
    { type: 'organizationRegistered', name: 'Зарегистрирована новая организация', icon: 'custom-new-organization', format: '/organizations/edit/{3}' },
    { type: 'userRegistered', name: 'Зарегистрирован новый пользователь', icon: 'custom-new-user', format: '/users/edit/{4}' }
]
