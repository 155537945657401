import { USER_MENU_PERMS, USER_PERMISSIONS_MAP } from '@/constants/common';

export default [
    {
        path: 'list',
        name: 'userList',
        component: () => import('@/pages/users/usersList.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.USERS.LIST
        },
    },
    {
        path: 'new/:orgId?',
        component: () => import('@/pages/users/newUser.vue'),
        props: true,
        meta: {
            permissionAccess: [USER_PERMISSIONS_MAP.userWriteAll]
        },
    },
    {
        path: 'edit/:userId',
        name: ':userId',
        props: true,
        component: () => import('@/pages/users/newUser.vue'),
        meta: {}
    },
];
