<template>
    <Dialog
        :visible.sync="showDeleteDialog"
        header=""
        position="center"
        :modal="true"
        :closeOnEscape="true"
        :closable="false"
        class='delete-accept-dialog'
    >
        <div class="p-dialog-content p-ai-center p-d-flex">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
            <span>Вы уверены, что хотите удалить данные?</span>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-start">
                <Button
                    class="p-button p-button-danger"
                    @click="acceptHandler"
                >
                    <span class="p-button-label">Удалить</span>
                </Button>
                <Button
                    class="p-button p-button-outlined p-button-secondary"
                    type="button"
                    @click="cancelHandler"
                >
                    <span class="p-button-label">Отменить</span>
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
export default {
    name: 'DeleteAcceptDialog',
    created() {
        this.$root.$on('showAcceptDeleteDialog', ({ acceptAction = () => {}, cancelAction = () => {} }) => {
            this.acceptAction = acceptAction
            this.cancelAction = cancelAction
            this.showDeleteDialog = true
            // this.deleteHandler()
        })
    },
    data() {
        this.acceptAction = () => {}
        this.cancelAction = () => {}
        return {
            showDeleteDialog: false
        }
    },
    methods: {
        // unsubscribeListeners() {
        //     this.$off('acceptDelete')
        //     this.$off('cancelDelete')
        // },
        async acceptHandler() {
            await this.acceptAction()
            this.showDeleteDialog = false
            // this.$emit('acceptDelete')
        },
        cancelHandler() {
            this.cancelAction()
            this.showDeleteDialog = false
            // this.$emit('cancelDelete')
        },
        // showDeleteDialogHandler() {
        //     return new Promise((resolve, reject) => {
        //         this.$on('acceptDelete', resolve)
        //         this.$on('cancelDelete', reject)
        //     })
        // },
        // async deleteHandler() {
        //     this.showDeleteDialog = true
        //     await this.showDeleteDialogHandler()
        //     this.unsubscribeListeners()
        //     this.showDeleteDialog = false
        // },
    },
    beforeDestroy() {
        // this.$root.$off('showAcceptDeleteDialog', ({ acceptAction, cancelAction }) => {
        //     this.acceptAction = acceptAction
        //     this.cancelAction = cancelAction
        // })
    }
};
</script>

<style lang='scss' scoped>
.p-dialog-mask {
    z-index: 9999!important;
}
</style>
