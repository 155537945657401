<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            header=""
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="false"
        >
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i
                    class="pi pi-exclamation-triangle p-mr-3"
                    style="font-size: 2rem"
                />
                <span>
                Вы переходите на другую страницу, возможно данные не сохранятся. Хотите сохранить данные?
            </span>
            </div>

            <template #footer>
                <Button class="p-button" type="button" autofocus @click="saveAction">
                    <span class="p-button-label">Сохранить</span>
                    <span class="p-ink"></span>
                </Button>
                <Button class="p-button p-button-outlined" @click="noSaveAction">
                    <span class="p-button-label black">Отменить</span>
                </Button>
            </template>
        </Dialog>
    </div>
</template>

<script>
export default {
    name: 'LeaveAcceptDialog',
    created() {
        this.$root.$on('show-leave-dialog', this.showDialogHandler)
    },
    beforeDestroy() {
        this.$root.$off('show-leave-dialog', this.showDialogHandler)
    },
    data() {
        return {
            acceptCb: null,
            cancelCb: null,
            beforeCLose: null,
            showDialog: false
        }
    },
    methods: {
        showDialogHandler({ acceptCb = null, cancelCb = null, beforeClose = () => {} }) {
            this.acceptCb = acceptCb
            this.cancelCb = cancelCb
            this.beforeClose = beforeClose
            this.showDialog = true
        },
        async saveAction() {
            try {
                this.$root.$emit('loadingChange', true)
                await this.acceptCb()
                this.beforeClose()
            } catch (error) {
                console.log(error.message);
            } finally {
                this.showDialog = false
                this.$root.$emit('loadingChange', false)
            }
        },
        async noSaveAction() {
            try {
                this.$root.$emit('loadingChange', true)
                if (this.cancelCb) await this.cancelCb()
                this.beforeClose()
            } catch (error) {
                console.log(error.message);
            } finally {
                this.$root.$emit('loadingChange', false)
                this.showDialog = false
            }
        },
        clearData() {
            this.cancelCb = null
            this.acceptCb = null
            this.beforeClose = null
        }
    },
    watch: {
        showDialog: {
            handler(to) {
                if (!to) this.clearData()
            }
        }
    }
};
</script>
