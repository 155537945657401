import { USER_MENU_PERMS, USER_PERMISSIONS_MAP } from '@/constants/common';

const {
    organizationWrite,
    organizationWriteAll,
    organizationWriteOwn,
    organizationReadOwn,
    organizationReadAll,
    organizationRead
} = USER_PERMISSIONS_MAP

export default [
    {
        path: 'list',
        name: 'organizationsList',
        component: () => import('@/pages/organizations/OrgsList.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.ORGANIZATIONS,
        },
    },
    {
        path: 'new/:mainOrgId?',
        name: 'organizationsNew',
        props: true,
        component: () => import('@/pages/organizations/OrgsNew.vue'),
        meta: {
            breadcrumb: [
                { label: 'Организации', to: '/organizations' },
                { label: 'Создание организации', to: '/organizations/new' },
            ],
            permissionAccess: [organizationWriteAll],
        },
    },

    {
        path: 'edit/:orgId',
        name: ':orgId',
        props: true,
        component: () => import('@/pages/organizations/OrgsNew.vue'),
        meta: {
            permissionAccess: [
                organizationWrite,
                organizationWriteOwn,
                organizationWriteAll,
                organizationReadOwn,
                organizationReadAll,
                organizationRead
            ],
        }
    },
    {
        path: 'edit/:orgId/:mainOrgId',
        name: ':mainOrgId',
        props: true,
        component: () => import('@/pages/organizations/OrgsNew.vue'),
        meta: {
            permissionAccess: [
                organizationWrite,
                organizationWriteOwn,
                organizationWriteAll,
                organizationReadOwn,
                organizationReadAll,
                organizationRead
            ],
        }
    }
];
