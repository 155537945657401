import notifications from '@/router/modules/notifications';
import periodicity from '@/router/modules/periodicity';
import roles from '@/router/modules/roles';
import { USER_MENU_PERMS } from '@/constants/common';

export default [
    {
        path: 'subordinations',
        name: 'subordinations',
        component: () => import('@/pages/control/subordinations/index.vue'),
        redirect: { path: '/control/subordinations/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL,
        },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/control/subordinations/subordinationsList.vue'),
                meta: {
                    permissionAccess: USER_MENU_PERMS.CONTROL
                },
            }
        ]
    },
    {
        path: 'locations',
        name: 'locations',
        component: () => import('@/pages/control/locations/index.vue'),
        redirect: { path: '/control/locations/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL
        },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/control/locations/locationsList.vue'),
                meta: {
                    permissionAccess: USER_MENU_PERMS.CONTROL
                },
            }
        ]
    },
    {
        path: 'types',
        name: 'types',
        component: () => import('@/pages/control/types/index.vue'),
        redirect: { path: '/control/types/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL
        },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/control/types/typesList.vue'),
                meta: {
                    permissionAccess: USER_MENU_PERMS.CONTROL
                },
            }
        ]
    },
    {
        path: 'tags',
        name: 'tags',
        component: () => import('@/pages/control/tags/index.vue'),
        redirect: { path: '/control/tags/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL
        },
        children: [
            {
                path: 'list',
                component: () => import('@/pages/control/tags/tagsList.vue'),
                meta: {
                    permissionAccess: USER_MENU_PERMS.CONTROL
                },
            }
        ]
    },
    {
        path: 'periodicity',
        name: 'periodicity',
        component: () => import('@/pages/control/periodicity/index.vue'),
        redirect: { path: '/control/periodicity/list' },
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL,
        },
        children: periodicity,
    },
    {
        path: 'roles',
        component: () => import('@/pages/control/roles/index.vue'),
        redirect: { path: '/control/roles/list' },
        meta: {},
        children: roles,
    },
    {
        path: 'notifications',
        component: () => import('@/pages/control/notifications/index.vue'),
        redirect: { path: '/control/notifications/list' },
        name: 'notifications',
        meta: {},
        children: notifications,
    }
];




