export default function(data, type, id) {
    this.data = {
        attributes: {},
        relationships: {}
    }
    delete data.id
    Object.keys(data).forEach(dataProp => {
        // console.log('%cprop', 'color: blue', dataProp, data[dataProp]);
        if (data[dataProp]) {
            switch (typeof data[dataProp]) {
                case 'number':
                    this.data.attributes[dataProp] = data[dataProp]
                    break
                case 'string':
                    this.data.attributes[dataProp] = data[dataProp]
                    break
                case 'object':
                    if (Array.isArray(data[dataProp])) {
                        this.data.relationships[dataProp] = { data: [] }
                        this.data.relationships[dataProp].data = data[dataProp].filter(isTrueId).map(i => ({ type: i.type, id: i.id }))
                        if (!this.data.relationships[dataProp].data.length) delete this.data.relationships[dataProp]
                    } else if (isTrueId(data[dataProp])) {
                        this.data.relationships[dataProp] = { data: {} }
                        this.data.relationships[dataProp].data = { type: data[dataProp].type, id: data[dataProp].id }
                    }
                    break
                case 'boolean':
                    this.data.attributes[dataProp] = data[dataProp]
            }
        } else if (typeof data[dataProp] === 'boolean') {
            this.data.attributes[dataProp] = data[dataProp]
        } else {
            this.data.attributes[dataProp] = ''
        }
        // console.log(typeof data[dataProp], dataProp);
    })
    if (id !== undefined) this.data.id = id
    this.data.type = type
    if (!Object.keys(this.data.relationships).length) delete this.data.relationships
}


function isTrueId(i) {
    return i.id === null || i.id
}
