<template>
    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <Toast />
        <DeleteAcceptDialog />
        <AssignmentDistributionDialog />
        <LeaveAcceptDialog/>
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <div class="layout-content-wrapper">
            <AppTopBar
                :topbarNotificationMenuActive="topbarNotificationMenuActive"
                :topbarUserMenuActive="topbarUserMenuActive"
                @menu-button-click="onMenuButtonClick"
                @search-click="toggleSearch"
                @topbar-notification="onTopbarNotificationMenuButtonClick"
                @topbar-user-menu="onTopbarUserMenuButtonClick"
                @right-menu-click="onRightMenuButtonClick"
                @right-menubutton-click="onRightMenuButtonClick"
            ></AppTopBar>

            <!-- <div :class="[constructorRightMenuOpen ? 'big-pading' : '', 'layout-content', 'p-d-flex']"> -->
            <div :class="['layout-content', 'p-d-flex']">
                <router-view />
            </div>

            <AppFooter />
        </div>

        <AppMenu
            class="customStyles custom-styles-sidebar"
            :model="menu"
            :layoutMode="layoutMode"
            :active="menuActive"
            :mobileMenuActive="staticMenuMobileActive"
            @menu-click="onMenuClick"
            @menuitem-click="onMenuItemClick"
            @root-menuitem-click="onRootMenuItemClick"
        ></AppMenu>

        <AppRightMenu :rightMenuActive="rightMenuActive" @right-menu-click="onRightMenuClick" @closeUserNotifications="rightMenuActive = !rightMenuActive"></AppRightMenu>

        <!-- <AppConfig v-model:configActive="configActive" v-model:layoutMode="layoutMode" v-model:menuTheme="menuTheme" v-model:colorScheme="colorScheme" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig> -->

        <AppSearch :searchActive="searchActive" @search-click="onSearchClick" @search-hide="onSearchHide" />

        <div class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
// import AppConfig from './AppConfig';
import AppMenu from './AppMenu';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';
import DeleteAcceptDialog from '@/components/common/dialogs/DeleteAcceptDialog';
import AssignmentDistributionDialog from '@/components/common/dialogs/AssignmentDistributionDialog';
import LeaveAcceptDialog from '@/components/common/dialogs/LeaveAcceptDialog';

import { mapGetters, mapMutations } from 'vuex';
import { USER_MENU_PERMS } from '@/constants/common';

const {
    FORMS,
    CONTROL,
    ORGANIZATIONS,
    USERS,
    UPLOADING_REPORTS,
    DATA_ENTRY,
    AGGREGATE,
    REGISTERS,
    FAQ
} = USER_MENU_PERMS

export default {
    data() {
        return {
            loading: false,
            menuActive: false,
            layoutMode: 'static',
            colorScheme: 'light',
            menuTheme: 'layout-sidebar-niioz',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarUserMenuActive: false,
            notificationMenuClick: false,
            topbarNotificationMenuActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            fullMenu: [
                {
                    label: 'Формы',
                    icon: 'pi icon pi-fw custom-form',
                    to: '/forms',
                    permissionAccess: FORMS
                },
                {
                    label: 'Организации',
                    icon: 'icon pi-fw custom-icon-organization',
                    to: '/organizations',
                    permissionAccess: ORGANIZATIONS,
                },
                {
                    label: 'Пользователи',
                    icon: 'pi pi-fw pi-users',
                    to: '/users',
                    permissionAccess: USERS.MAIN,
                },
                {
                    label: 'Заполнение',
                    icon: 'pi pi-fw pi-pencil',
                    to: '/dataEntry/list',
                    permissionAccess: process.env.VUE_APP_SHOW_ENTRY === 'true' ? DATA_ENTRY : CONTROL,
                },
                {
                    label: 'Своды',
                    icon: 'icon pi-fw custom-summary',
                    to: '/aggregate/list',
                    permissionAccess: AGGREGATE,
                },
                {
                    label: 'Прочие ресурсы',
                    icon: 'icon pi-fw custom-registers',
                    to: '/registers/list',
                    permissionAccess: REGISTERS,
                },
                {
                    label: 'Выгрузки',
                    icon: 'pi pi-fw pi-download',
                    to: '/unloadingReports',
                    permissionAccess: UPLOADING_REPORTS,
                },
                {
                    label: 'Управление',
                    icon: 'pi pi-fw pi-cog leftMar',
                    items: [
                        {
                            label: 'Подчинения',
                            icon: 'pi pi-fw pi-sitemap',
                            to: '/control/subordinations',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Расположения',
                            icon: 'pi pi-fw pi-map-marker',
                            to: '/control/locations',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Типы организации',
                            icon: 'pi pi-fw pi-inbox',
                            to: '/control/types',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Теги',
                            icon: 'pi pi-fw pi-tags',
                            to: '/control/tags',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Периодичность',
                            icon: 'pi icon pi-fw custom-icon-calendar',
                            to: '/control/periodicity',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Роли',
                            icon: 'pi pi-fw pi-user-plus',
                            to: '/control/roles',
                            permissionAccess: CONTROL
                        },
                        {
                            label: 'Уведомления',
                            to: '/control/notifications',
                            icon: 'pi pi-fw pi-bell',
                            permissionAccess: CONTROL
                        },
                    ],
                    permissionAccess: CONTROL,
                },
                {
                    label: 'Справка',
                    icon: 'icon pi-fw custom-question',
                    to: '/FAQ/FAQList',
                    permissionAccess: FAQ,
                },
            ],
            menu: [
                {
                    icon: 'pi pi-fw pi-home',
                    items: [],
                },
            ],
        };
    },
    computed: {
        ...mapGetters('auth', ['currentUserRoleId']),

        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-sidebar-dim': this.colorScheme === 'dim',
                    'layout-sidebar-dark': this.colorScheme === 'dark',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
                    'p-input-filled': this.$appState.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.ripple,
                },
                this.colorScheme === 'light' ? this.menuTheme : '',
            ];
        },
    },
    components: {
        AppTopBar,
        AppFooter,
        // AppConfig,
        AppMenu,
        AppSearch,
        AppRightMenu,
        DeleteAcceptDialog,
        AssignmentDistributionDialog,
        LeaveAcceptDialog
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        ...mapMutations([
            'setRootEmit'
        ]),
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.userMenuClick) {
                this.topbarUserMenuActive = false;
            }

            if (!this.notificationMenuClick) {
                this.topbarNotificationMenuActive = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim()) {
                    EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.rightMenuClick = false;
            this.notificationMenuClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarNotificationMenuActive = false;
            this.rightMenuActive = false;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }
            this.$root.$emit('render-table');

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isSlim()) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onTopbarUserMenuButtonClick(event) {
            this.userMenuClick = true;
            this.topbarUserMenuActive = !this.topbarUserMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.notificationMenuClick = true;
            this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightMenuActive = !this.rightMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isOverlay() {
            return this.layoutMode === 'overlay';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        menuItems() {
            this.menu[0].items = this.fullMenu
            // this.fullMenu.forEach((menuItem) => {
            //     if (menuItem.some(({ permissionAccess } => ).includes(this.currentUserRoleId)) {
            //         this.menu[0].items.push(menuItem);
            //     }
            // });
        },
    },
    created() {
        this.$root.$on('loadingChange', (value = false) => this.loading = value)
        this.setRootEmit(this.$root.$emit)
    },
    mounted() {
        this.menuItems();
    },
};
</script>

<style lang="scss">
@import 'App.scss';
.custom-styles-sidebar {
    z-index: 2500;
}
.big-pading {
    padding-right: 1rem;
}
.layout-content {
    animation: all 1s;
}
::v-deep .leftMar {
    margin-left: 3px;
}
.p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
</style>
