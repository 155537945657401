import { USER_MENU_PERMS, USER_PERMISSIONS_MAP } from '@/constants/common';

export default [
  {
    path: 'list',
    name: 'rolesList',
    component: () => import('@/pages/control/roles/rolesList.vue'),
    meta: {
        permissionAccess: USER_MENU_PERMS.CONTROL
    },
  },
  {
    path: 'new',
    component: () => import('@/pages/control/roles/newRole.vue'),
    meta: {
        permissionAccess: [USER_PERMISSIONS_MAP.rbacWrite]
    },
  },
  {
    path: 'edit/:roleId',
    component: () => import('@/pages/control/roles/newRole.vue'),
    props: true,
    meta: {
        permissionAccess: [USER_PERMISSIONS_MAP.rbacWrite]
    },
  },
]
