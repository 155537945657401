<template>
    <div class="login-body">
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel">
                <img class="p-mb-6" src="assets/layout/images/login-logo.svg" alt="diamond-layout" />
                <h3>Регистрация завершена!</h3>
                <i class="pi p-my-3 pi-check-circle" style="fontsize: 2rem"></i>
                <p class="p-my-2">Ваша заявка отправлена на рассмотрение. Вы получите уведомление на указанный вами e-mail, как только процедура будет завершена.</p>
                <p class="p-mb-6">По всем вопросам обращайтесь в службу поддержки НИИОЗМ по телефону:<br>
                    <a href="tel:84954171209" target="_blank" class="telColor">8 (495) 417-12-09 доб. 313/323</a>
                </p>
                <div class="p-mb-0 p-mr-auto">
                    <Button class="p-mr-2 p-mb-0 p-button-text" @click="$router.push('/login')" label="Продолжить" />
                </div>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import LoginImage from '@/components/loginComponents/LoginImage';

export default {
    name: 'RegistrationConfirm',
    components: { LoginImage },
};
</script>

<style lang="scss" scoped>
.login-body .login-wrapper .login-panel p {
    font-weight: 400;
    color: black;
}

.telColor {
    color: #606060 !important;
}
</style>
