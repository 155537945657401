import { USER_MENU_PERMS } from '@/constants/common';

export default [
    {
        path: 'list',
        name: 'aggregateList',
        component: () => import('@/pages/dataEntry/aggregateList'),
        props: true,
        meta: {
            permissionAccess: USER_MENU_PERMS.AGGREGATE,
        },
    },
    {
        path: ':templateId/',
        name: 'aggregate-templateId',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {},
    },
    {
        path: ':templateId/:aggregateId/',
        name: 'aggregate-aggregateId',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {},
    },
    {
        path: ':templateId/:aggregateId/:formDistributionId/',
        name: 'aggregate-review',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            review: true
        },
    },
    {
        path: ':templateId/:aggregateId/:formDistributionId/:worksheetId/',
        name: 'aggregate-review-list',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            review: true
        },
    },
]
