import { USER_MENU_PERMS } from '@/constants/common';

export default [
    {
        path: 'list',
        name: 'dataEntryList',
        component: () => import('@/pages/dataEntry/dataEntryList'),
        props: true,
        meta: {
            breadcrumb: [],
            permissionAccess: USER_MENU_PERMS.DATA_ENTRY,
        },
    },
    {
        path: ':distributionId/',
        name: ':distributionId/',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            breadcrumb: [],
            permissions: ['1']
        },
    },
    {
        path: ':distributionId/:worksheetId/',
        name: ':worksheetId/',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            breadcrumb: [],
            permissions: ['1']
        },
    },
    {
        path: 'aggregate/:templateId/',
        name: 'templateId',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            breadcrumb: [],
            permissions: ['1']
        },
    },
    {
        path: 'aggregate/:templateId/:aggregateId/',
        name: 'aggregateId',
        component: () => import('@/pages/dataEntry/fill'),
        props: true,
        meta: {
            breadcrumb: [],
            permissions: ['1']
        },
    },
]
