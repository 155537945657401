<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <span class="app-name">Служба технической поддержки: 8 (495) 417-12-09 доб. 313/323, helpcms@zdrav.mos.ru</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
};
</script>

<style lang="scss">
.layout-footer .footer-logo-container .app-name {
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #949494;
}
</style>
