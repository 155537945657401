<template>
    <div class="login-body">
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel p-pr-6 p-pl-6">
                <form @submit.prevent="onSubmit" class="login-form">
                    <div :class="inputGroupClasses">
                        <h3 class="login_title">Регистрация Организации</h3>
                    </div>
                    <div :class="inputGroupClasses">
                        <div class="p-inputgroup">
                            <InputText name="tin"
                                       v-model.trim="v$.formData.tin.$model"
                                       placeholder="ИНН организации*"
                                       :class="(v$.formData.tin.$invalid && submitted) || (v$.formData.tin.$invalid && tinSubmitted) ? 'p-error' : ''"
                                       @keydown="onKeyDown" />
                            <Button class="p-button-text p-button-primary searchButtonDecorate" @click="findOrganization" icon="pi pi-search" />
                        </div>
                        <small v-show="submitted" class="p-error errorPosition">
                            <span v-for="error in showErrors(v$.formData.tin)" :key="error.$uid">{{ error.$message }}&nbsp;</span></small>
                        <small v-if="!submitted" class="p-error p-text-nowrap p-text-truncate errorPosition">{{ organizationByInnError }}</small>
                    </div>
                    <div :class="inputGroupClasses">
                        <div :class="organizationFullName.length ? 'bgc' : ''">
                            <p class="p-text-left">{{ organizationFullName }}</p>
                        </div>
                    </div>
                    <div :class="inputGroupClasses">
                        <InputText name="organizationEditName" v-model.trim="formData.organizationEditName" placeholder="Название организации*" :class="v$.formData.organizationEditName.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.formData.organizationEditName)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
                        <small v-if="submitted" class="p-error p-text-nowrap p-text-truncate errorPosition">{{ organizationExist }}</small>
                    </div>
                    <div :class="inputGroupClasses">
                        <InputText name="contactFullName" v-model.trim="v$.formData.contactFullName.$model" placeholder="Контактное лицо (ФИО)*" :class="v$.formData.contactFullName.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.formData.contactFullName)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
                    </div>
                    <div :class="inputGroupClasses">
                        <InputText name="contactTelephone" v-model.trim="v$.formData.contactTelephone.$model" placeholder="Контактный телефон*" :class="v$.formData.contactTelephone.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.formData.contactTelephone)" :key="error.$uid" class="p-error errorPosition">
                            {{ error.$message }}
                        </small>
                    </div>
                    <div :class="inputGroupClasses">
                        <InputText name="contactEmail" v-model.trim="v$.formData.contactEmail.$model" placeholder="Ваш e-mail*" :class="v$.formData.contactEmail.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.formData.contactEmail)" :key="error.$uid" class="p-error errorPosition">{{ error.$message }}</small>
                    </div>
                    <div class="p-mb-0" :class="inputGroupClasses">
                        <div class="p-field-checkbox p-text-left p-mb-2 p-d-flex p-ai-start">
                            <Checkbox v-model="v$.formData.agreement.$model" :binary="true" name="agreement" id="agreement" :class="v$.formData.agreement.$invalid && submitted ? 'p-error' : ''" />
                            <label for="agreement">
                                Я ознакомлен с <a class="decorateText" href="https://niioz.ru/upload/iblock/f6b/f6b3d8173523b37ebc3371ce3dbd1dec.pdf" target="blank">Условиями использования Сайта</a>,
                                <a
                                    class="decorateText"
                                    href="https://niioz.ru/upload/doc/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%A1%D0%90%D0%99%D0%A2%20NIIOZ.pdf"
                                    target="blank"
                                    >Политикой обработки персональных данных</a
                                >
                                и даю согласие на обработку персональных данных.
                            </label>
                        </div>
                        <small v-if="v$.formData.agreement.$invalid" class="p-error" style="margin: 10px; text-align: left;">{{ 'Необходимо дать согласие на обработку персональных данных' }}</small>
                    </div>
                    <div class="p-mb-0 p-px-2 p-pt-2">
                        <Button class="p-mb-0 p-button p-pl-4 p-pr-4 customPadding" label="Отправить заявку" type="submit" />
                    </div>
                    <div class="p-mb-0" :class="inputGroupClasses">
                        <p class="p-text-left">Организация уже зарегистрирована в системе или не находится по ИНН?</p>
                    </div>

                    <div class="p-mb-0" :class="inputGroupClasses">
                        <p class="p-text-left">Обратитесь в службу поддержки НИИОЗМ по телефону:<a href="tel:84954171209" target="_blank" class="telColor">8 (495) 417-12-09 доб. 313/323</a></p>
                    </div>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-2 p-button-text decorateText p-py-1" @click="$router.push('/registrationUser')" label="Регистрация пользователя" />
                    </div>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-2 p-button-text decorateText p-py-1" @click="$router.push('/login')" label="Войти" />
                    </div>
                </form>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import { required, minLength, email, numeric, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import LoginImage from '@/components/loginComponents/LoginImage';
import { registerNewOrganization } from '@/api/organization';
import { getOrgRequisitesByInn } from '@/api/organization/index';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'registrationOrganization',
    components: { LoginImage },
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        formData: {
            tin: '',
            organizationEditName: '',
            contactFullName: '',
            contactTelephone: '',
            contactEmail: '',
            agreement: true,
        },
        organizationFullName: 'Наименование Организации по результатам введеннго ИНН',
        organizationByInn: null,
        organizationByInnError: '',
        organizationExist: '',
        submitted: false,
        tinSubmitted: false,
        inputGroupClasses: ['p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-py-2', 'p-px-2', 'errorPos'],
    }),
    validations() {
        const agree = () => this.formData.agreement;
        const telephoneMask = val => val.length > 0 ? /^[0-9-+)( ]+$/.test(val) : 1;
        const INNLength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 10 : true;
            } else {
                return true;
            }
        }
        return {
            formData: {
                tin: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    INNLength: helpers.withMessage(
                        'Длина 10 символов',
                        INNLength
                    ),
                },
                organizationEditName: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                },
                contactFullName: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(3), $message: 'Длина не менее 3 символов' },
                },
                contactTelephone: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    telephoneMask: helpers.withMessage('Неверный формат телефона', telephoneMask),
                },
                contactEmail: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    email: { ...email, $message: 'Введите корректный e-mail' },
                },
                agreement: {
                    agree: agree,
                },
            },
        };
    },
    methods: {
        async onSubmit() {
            this.organizationByInnError = '';
            this.organizationExist = '';
            this.submitted = true;
            try {
                if (this.organizationByInn == null) {
                    // this.organizationByInnError = 'Проведите поиск по ИНН';
                    let result = await this.findOrganization();
                    if (!result) return;
                }

                if (!this.v$.$invalid) {
                    await this.registerOrganization();
                }
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        async registerOrganization() {
            const dataToServer = {
                data: {
                    type: 'organization',
                    id: null,
                    attributes: {
                        fullName: this.formData.organizationEditName || this.organizationFullName,
                        shortName: this.organizationByInn.attributes.shortName,
                        active: false,
                        status: 'new',
                        inn: this.organizationByInn.attributes.inn,
                        legalKpp: this.organizationByInn.attributes.kpp,
                        legalOgrn: this.organizationByInn.attributes.ogrn,
                        legalAddress: this.organizationByInn.attributes.address,
                        legalOkpo: this.organizationByInn.attributes.okpo,
                        legalOkved: this.organizationByInn.attributes.okved,
                        legalOkonh: this.organizationByInn.attributes.okonh,
                        legalOkato: this.organizationByInn.attributes.okato,
                        legalOkogu: this.organizationByInn.attributes.okogu,
                        legalOkdp: this.organizationByInn.attributes.okdp,
                        contactName: this.formData.contactFullName,
                        contactPhone: this.formData.contactTelephone,
                        contactEmail: this.formData.contactEmail,
                    },
                },
            };
            try {
                const response = await registerNewOrganization(dataToServer);
                if (response.message) {
                    this.organizationByInnError = `${response.message}`;
                    return;
                }
                await this.$router.push('/registrationConfirm');
            } catch (error) {
                this.organizationExist = `${error.message}`;
                return;
            }
        },
        onKeyDown() {
            this.organizationByInnError = '';
            this.organizationFullName = '';
            this.organizationEditName = '';
        },
        async findOrganization() {
            this.organizationByInnError = '';
            this.organizationExist = '';
            this.organizationFullName = 'Наименование Организации по результатам введеннго ИНН';
            this.formData.organizationEditName = '';
            this.tinSubmitted = true;
            if (!this.v$.formData.tin.$invalid && this.formData.tin.length === 10) {
                try {
                    const response = await getOrgRequisitesByInn(this.formData.tin);
                    if (response.message) {
                        this.organizationByInnError = `${response.message}`;
                        return;
                    }
                    this.organizationByInn = response;
                    this.organizationFullName = response.attributes.fullName;
                    this.formData.organizationEditName = response.attributes.fullName;
                    this.tinSubmitted = false;
                } catch (error) {
                    this.organizationByInnError = `${error.message}`;
                    return false;
                }
            } else {
                if (this.formData.tin) {
                    this.organizationByInnError = 'Длина ИНН 10 цифр.';
                }
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    watch: {
        'formData.tin': function () {
            this.organizationByInnError = '';
        },
    },
};
</script>

<style lang="scss" scoped>

.login-body .login-wrapper .login-panel .bgc {
    background: #f5fdf6;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    p {
        font-weight: 400;
    }
}

.errorPos {
    position: relative;
    margin-bottom: 16px;
}

.errorPosition {
    position: absolute;
    left: 0.5rem;
    bottom: -10px;
}

.searchButtonDecorate {
    border: 1px solid #eaeaea;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.customPadding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.decorateText:hover {
    text-decoration: underline;
}

.login-body .login-wrapper .login-panel p {
    font-weight: 400;
    color: black;
}

.p-inputtext {
    padding: 0.5rem 1rem;
}

.login_title {
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #4a4a4a;
    margin-bottom: 0;
}

a {
    color: #388e3c;
    font-size: 14px;
    line-height: 14px;
}

.telColor {
    color: #606060 !important;
}

</style>
