import { USER_MENU_PERMS } from '@/constants/common';

export default [
    {
        path: 'list',
        name: 'registersList',
        component: () => import('@/pages/registers/registersList.vue'),
        meta: {
            breadcrumb: [],
            permissionAccess: USER_MENU_PERMS.REGISTERS,
        },
    }
]
