import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { downloadFile, generateErrors } from '@/main/utils/common';
import { onlyCompletedFields } from '@/utils/common';

export const getAllForms = async ({ page = 1, pageSize = PAGE_SIZE, relationShip = false, filter = {}, sort}) => {
    try {
        const { data: { data, included, meta } } = await axios.get(`${apiUrl}/form`, { params: {
                'page-size': pageSize,
                page,
                filter: JSON.stringify(onlyCompletedFields(filter)),
                sort,
                include: relationShip ? 'distributionInterval,groups' : ''
            }
        });
        return {
            forms: data,
            included: included,
            meta: meta
        }
    } catch (error) {
        generateErrors(error);
    }
};

export async function downloadForms({ filter = {} }) {
    try {
        const result = await axios.get(`${ apiUrl }/form/download/xlsx`, {
            params: {
                page: 1,
                'page-size': PAGE_SIZE,
                filter: filter
            },
            responseType: 'blob'
        });

        downloadFile('forms', result.data);
    } catch (error) {
        generateErrors(error);
    }
}

export async function getFormById(formId) {
    try {
        const { data: { data, included, meta } } = await axios.get(`${ apiUrl }/form/${ formId }`, {
            params: {
                include: 'distributionInterval,groups'
            }
        });

        return {
            data: data,
            included: included,
            meta: meta
        };
    } catch (error) {
        generateErrors(error);
    }
}

export async function createForm(formData) {
    try {
        const { data } = await axios.post(`${apiUrl}/form`, formData)
        return data
    } catch (error) {
        generateErrors(error)
    }
}

export async function editFormParametrs(formId, data) {
    try {
        const response = await axios.patch(`${apiUrl}/form/${formId}`, data)
        return response.data
    } catch (error) {
        generateErrors(error)
    }
}

export const createNewFormsdistribute = async (formId, data) => {
    try {
        const response = await axios.post(`${apiUrl}/form/${formId}/distribute`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const fetchFormPermissions = async (userId, filter = {}) => {
    try {
        const { data } = await axios.get(`${apiUrl}/user/${userId}/relationships/forms`, {
            params: {
                filter: JSON.stringify(filter),
                include: 'form',
                'page-size': PAGE_SIZE,
                page: 1
            }
        })
        return data
    } catch (error) {
        generateErrors(error);
    }
}

export const getFormGroupPermissions = async (userId, formIds) => {
    try {
        const { data } = await axios.get(`${ apiUrl }/user/${ userId }/relationships/forms?${ formIds }`, {
            params: {
                'page-size': PAGE_SIZE,
                page: 1
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
}

export const addFormPermissions = async (userId, data) => {
    try {
        const response = await axios.post(`${apiUrl}/user/${userId}/relationships/forms`, { data })
        return response.data
    } catch (error) {
        generateErrors(error);
    }
}

export const deleteFormPermissions = async (userId, data) => {
    try {
        const response = await axios.delete(`${apiUrl}/user/${userId}/relationships/forms`, { data: { data } })
        return response.data
    } catch (error) {
        generateErrors(error);
    }
}

export const createWorksheetGroup = async (formId, data) => {
    try {
        const request = await axios.post(`${ apiUrl }/form/${ formId }/relationships/groups`, data);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const updateWorksheetGroup = async (groupId, data) => {
    try {
        const request = await axios.patch(`${ apiUrl }/form-group/${ groupId }`, data);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};
