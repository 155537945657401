export default [
  {
    path: '/',
    name: 'dashboard',
    redirect: '/forms/list',
    exact: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: {
        noProtected: true
    }
  },
  {
    path: '/registrationUser',
    name: 'registrationUser',
    component: () => import('@/pages/register/RegistrationUser.vue'),
      meta: {
        noProtected: true
      }

  },
  {
    path: '/registrationOrganization',
    name: 'registrationOrganization',
    component: () => import('@/pages/register/RegistrationOrganization.vue'),
      meta: {
        noProtected: true
      }
  },
  {
    path: '/registrationConfirm',
    name: 'registrationConfirm',
    component: () => import('@/pages/register/RegistrationConfirm.vue'),
      meta: {
        noProtected: true
      }
  },
  {
    path: '/passRecovery',
    name: 'passRecovery',
    component: () => import('@/pages/password/PassRecovery.vue'),
      meta: {
          noProtected: true
      }
  },
  {
    path: '/passRecoveryConfirm',
    name: 'passRecoveryConfirm',
    component: () => import('@/pages/password/PassRecoveryConfirm.vue'),
      meta: {
        noProtected: true
      }
  },
  {
    path: '/passwordChange',
    name: 'passwordChange',
    component: () => import('@/pages/password/PasswordChange.vue'),
    meta: {
        noProtected: true
    },
  },
  {
    path: '/passwordChangeConfirm',
    name: 'passwordChangeConfirm',
    component: () => import('@/pages/password/PasswordChangeConfirm.vue'),
    meta: {
        noProtected: true
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/error/Error.vue'),
  },
  {
    path: '/accessDenied',
    name: 'accessDenied',
    component: () => import('@/pages/accessDenied/accessDenied.vue'),
  },
{
    path: '/serviceStop',
    name: 'serviceStop',
    component: () => import('@/pages/serviceStop/serviceStop.vue'),
},
]
