import { apiUrl, axios } from '@/api/api.js';
import { downloadFile, generateErrors } from '@/main/utils/common';
import { PAGE_SIZE } from '@/constants/common';

export async function registerNewOrganization(data) {
    try {
        const { data: result } = await axios.post(`${ apiUrl }/organization`, data);

        return result;
    } catch (error) {
        generateErrors(error);
    }
}

export const deleteOrganization = async id => {
    try {
        return await axios.delete(`${ apiUrl }/organization/${ id }`);
    } catch(error) {
        generateErrors(error);
    }
};

export async function getOrganizations({
                                           page = 1,
                                           pageSize = PAGE_SIZE,
                                           relationShip = false,
                                           sortParametr = '',
                                           filter = {},
                                       }) {
    const params = {
        'page': page,
        'page-size': pageSize,
        filter: JSON.stringify(filter),
    };
    if (sortParametr.length) params.sort = sortParametr;
    if (relationShip) params.include = 'location,type,parent,subordinateTo,district,parent.district,parent.location,parent.type,parent.subordinateTo,parent.parent';
    try {
        const response = await axios.get(`${apiUrl}/organization`, {
            params,
        });

        return {
            organizations: [...response.data.data],
            included: response.data.included ? [...response.data.included] : [],
            meta: response.data.meta,
        };
    } catch (error) {
        generateErrors(error);
    }
}

export const patchOrganization = async (data, id) => {
    try {

        const response = await axios.patch(`${apiUrl}/organization/${id}`, data);

        if (!data) {
            throw new Error('Обработанный текст ошибки');
        }
        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export async function getOrganizationById(id) {
    try {
        const { data: response } = await axios.get(`${ apiUrl }/organization/${ id }`, {
            params: {
                include: 'location,type,parent,subordinateTo,history,tags,district',
            }
        });

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export async function getOrganizationByInn(inn) {
    try {
        const { data: { data } } = await axios.get(`${ apiUrl }/organization/by-inn/${ inn }`, {
            params: {
                filter: {
                    parentId: { $eq: null }
                }
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
}

export async function getOrgRequisitesByInn(inn) {
    try {
        const response = await axios.get(`${apiUrl}/requisites/${inn}`);
        const organizations = response.data.data;

        return organizations;
    } catch (error) {
        if (error.isAxiosError) {
            let status = error.response.status;
            switch (status) {
                case 404:
                    throw new Error('Организация не найдена');
                case 500:
                    generateErrors(error);
            }
        }

        return error;
    }
}

export async function downloadOrganizations({ filter = {} }) {
    try {
        const result = await axios.get(`${ apiUrl }/organization/download/xlsx`, {
            params: {
                filter: filter
            },
            responseType: 'blob'
        });

        downloadFile('organizations', result.data);
    } catch (error) {
        generateErrors(error);
    }
}

export async function getOrganizationAssignments({
    organizationId,
    page = 1,
    pageSize = PAGE_SIZE,
    sortParametr = '',
    include = '',
    filter = {},
}) {
    const params = {
        page,
        'page-size': pageSize,
        filter: JSON.stringify(filter),
        include
    };
    if (sortParametr.length) params.sort = sortParametr;
    try {
        const { data } = await axios.get(`${apiUrl}/organization/${organizationId}/relationships/form-assignments`, {
            params
        })
        return data
    } catch (error) {
        generateErrors(error);
    }
}

export async function getOrganizationLicenses(organizationId, { page = 1, pageSize = PAGE_SIZE, filter = {}, sort = '' }) {
    try {
        return await axios.get(`${ apiUrl }/organization/${ organizationId }/relationships/licensed-works`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                sort
            }
        });
    } catch (error) {
        generateErrors(error);
    }
}

export const loadLicensesOrganizations = async (file) => {
    try {
        return await axios.post(`${ apiUrl }/organization/licensed-works`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        generateErrors(error);
    }
}
