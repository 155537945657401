<template>
    <Dialog
        :visible.sync="showDialog"
        position="center"
        :modal="true"
        :closeOnEscape="true"
        :closable="true"
        class="assignment-distribution-dialog"
        :contentStyle="{ overflow: 'visible' }"
    >
        <template #header>{{ dialogTitle }}</template>
        <div class='form-list-dialog__content'>
            <div class="p-field">
                <label class='field-label'>Форма</label>
                <Dropdown
                    v-model='selectedForm'
                    :options='forms'
                    placeholder=''
                    option-label='name'
                    filter
                    dataKey='id'
                    :disabled='disabledFormsMultiselect'
                    @filter='debouncedFormFilter'
                ></Dropdown>
            </div>
            <div class="p-field">
                <div>
                    <label class='field-label'>Организации*</label>
                    <i
                        v-if='!isDistributionMode'
                        style='color: var(--primary-color); cursor:pointer;'
                        class='p-ml-1 pi pi-question-circle'
                        title='Добавить к назначенным ранее'
                    />
                </div>
                <div v-if='isDistributionMode' class='radio-btns-container'>
                    <div class="p-field-radiobutton p-mb-0">
                        <RadioButton
                            id='allAssignments'
                            :value='sendingTypes.allAssignments'
                            v-model='sendingType'
                            :disabled='disabledRadioButtons'
                        />
                        <label for="allAssignments"
                               :class='{"p-disabled": disabledRadioButtons}'
                        >
                            Раздать всем назначенным
                        </label>
                    </div>
                    <div class="p-field-radiobutton p-mb-0">
                        <RadioButton
                            id='appendToAssignments'
                            :value='sendingTypes.appendToAssignments'
                            v-model='sendingType'
                            :disabled="disabledRadioButtons"
                        />
                        <label for="appendToAssignments"
                               :class='{"p-disabled": disabledRadioButtons}'
                        >
                            Добавить организации в список назначения и раздать всем
                        </label>
                    </div>
                    <div class="p-field-radiobutton p-mb-0">
                        <RadioButton
                            id='sendOnlySelected'
                            :value='sendingTypes.sendOnlySelected'
                            v-model='sendingType'
                            :disabled="disabledRadioButtons"
                        />
                        <label for="sendOnlySelected"
                               :class='{"p-disabled": disabledRadioButtons}'
                        >
                            Добавить организации в список назначения и раздать только выбранным
                        </label>
                    </div>
                </div>
                <MultiSelect26
                    v-model='formData.organizations'
                    :options='organizations'
                    :disabled='disabledOrgsMultiSelect'
                    option-value='id'
                    placeholder='Выберите из списка организаций'
                    option-label='shortName'
                    display='chip-count'
                    dataKey='id'
                    class='p-mt-2'
                    filter
                />
            </div>
            <div
                v-if='isDistributionMode'
                class="p-field"
            >
                <label class='field-label'>Период*</label>
                <MultiSelect26
                    v-model='formData.periods'
                    :options='formPeriods'
                    data-key='id'
                    option-value='id'
                    placeholder='Выберите из списка периодов'
                    option-label='name'
                    display="chip-count"
                    filter
                />
            </div>
        </div>
        <template #footer>
            <div class="p-d-flex p-jc-start">
                <Button
                    :disabled='disabledDialogSaveBtn'
                    class="p-button"
                    type="button"
                    @click='sendFormHandler'
                >
                    <span class="p-button-label">{{ saveButtonTitle }}</span>
                    <span class="p-ink"></span>
                </Button>
                <Button @click='showDialog = false' class="p-button p-button-outlined">
                    <span class="p-button-label black"> Отменить </span>
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
/**
 * For open this dialog need send to root $emit with name 'show-send-form'
 * and with payload: { mode(String)?, orgs(Array)?, form(Object)?, cbAfterSave(function)? }
 */
import SendForm from '@/models/SendForm';
import { getOrganizations } from '@/api/organization';
import { jsonApiListParser } from '@/main/utils/common';
import { getAllIntervalPeriods } from '@/api/form/formsPeriods';
import { createNewFormsdistribute, getAllForms } from '@/api/form';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { createFormAssignments } from '@/api/form/formAssignments';
import { requestToastHandler } from '@/main/mixins';
import { ASSIGNMENT_DISTRIBUTION_MODES } from '@/constants/forms';
import { SHOW_SEND_FORM_EVENT, DEFAULT_PAGE_SIZE, PAGE_SIZE, FORM_PERMISSIONS_MAP } from '@/constants/common';
import { mapGetters } from 'vuex';

const { DISTRIBUTION, ASSIGNMENT } = ASSIGNMENT_DISTRIBUTION_MODES

const { distribute } = FORM_PERMISSIONS_MAP

export default {
    name: 'AssignmentDistributionDialog',
    mixins: [requestToastHandler],
    computed: {
        ...mapGetters('auth', [ 'formPermissionsObject' ]),
        forOnlyAssignments() {
            return this.sendingType === this.sendingTypes.allAssignments
        },
        disabledFormsMultiselect() {
            return !!this.formFromEvent
        },
        disabledRadioButtons() {
            return !!this.organizationsFromEvent
                || (this.formPermissionsObject[this.selectedForm.id] && this.formPermissionsObject[this.selectedForm.id][distribute])
        },
        disabledOrgsMultiSelect() {
            const { allAssignments } = this.sendingTypes
            return (allAssignments === this.sendingType && this.isDistributionMode) || !!this.organizationsFromEvent
        },
        dialogTitle() {
            return this.isDistributionMode ? 'Раздача формы' : 'Назначение формы'
        },
        saveButtonTitle() {
            return this.isDistributionMode ? 'Раздать форму' : 'Назначить форму'
        },
        disabledDialogSaveBtn() {
            const { organizations, periods } = this.formData || {}
            return (!organizations.length && (!this.forOnlyAssignments || !this.isDistributionMode)) ||
                (this.isDistributionMode && !periods.length) ||
                !this.selectedForm.id
        },
        isDistributionMode() {
            return this.mode === DISTRIBUTION
        },
    },
    data() {
        this.sendingTypes = {
            allAssignments: 'allAssignments',
            onlySelectedOrgs: 'onlySelectedOrgs',
            sendOnlySelected: 'sendOnlySelected',
            excludeFromAssignments: 'excludeFromAssignments',
            appendToAssignments: 'appendToAssignments'
        }
        this.modes = ASSIGNMENT_DISTRIBUTION_MODES
        this.pageSize = PAGE_SIZE;
        return {
            filterTimeout: null,
            currentPage: 1,
            loading: false,
            showDialog: false,
            sendingType: this.sendingTypes.allAssignments,
            formData: new SendForm() || {},
            mode: ASSIGNMENT,
            selectedForm: {},
            forms: [],
            organizations: [],
            formPeriods: [],
            formFromEvent: null,
            organizationsFromEvent: null,
            listFilters: null,
            cbAfterSave: null
        }
    },
    created() {
        this.$root.$on(SHOW_SEND_FORM_EVENT, this.openDialogHandler)
    },
    beforeDestroy() {
        this.$root.$off(SHOW_SEND_FORM_EVENT, this.openDialogHandler)
    },
    methods: {
        async fetchInitOrgs() {
            try {
                this.$root.$emit('loadingChange', true);
                let filter = { $and : [{
                    $or: [
                        {
                            'status.activeUntil': {
                                $gte: new Date(),
                            },
                        },
                        { 'status.activeUntil': null },
                    ]
                }] };
                filter['$and'].push({ parentId: null });
                if (this.listFilters?.subordinateTo?.length > 0) {
                    filter['$and'].push({ subordinateToId: { $in: this.listFilters.subordinateTo } });
                }

                if (this.listFilters?.location?.length > 0) {
                    filter['$and'].push({ locationId: { $in: this.listFilters.location } });
                }

                if (this.listFilters?.district?.length > 0) {
                    filter['$and'].push({ districtId: { $in: this.listFilters.district } });
                }

                if (this.listFilters?.type?.length > 0) {
                    filter['$and'].push({ typeId: { $in: this.listFilters.type } });
                }

                const { organizations, included } = await getOrganizations({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    filter
                })
                const selectedOrgs = jsonApiListParser(organizations, included)

                this.organizations.push(...selectedOrgs)
            } catch (error) {
                this.$requestError(error.message)
            } finally {
                this.$root.$emit('loadingChange', false)
            }
        },
        async fetchInitForms() {
            try {
                this.$root.$emit('loadingChange', true)
                const { forms, included } = await getAllForms({
                    pageSize: DEFAULT_PAGE_SIZE,
                    relationShip: true,
                    filter: {
                        active: { '$eq': true }
                    },
                });
                this.forms = jsonApiListParser(forms, included)
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange', false)
            }
        },
        async fetchFormPeriods() {
            if (!this.isDistributionMode) return
            try {
                this.$root.$emit('loadingChange', true)
                const { data: allIntervalPeriods, included: incPeriods } = await getAllIntervalPeriods({
                    intervalId: this.selectedForm.distributionInterval.id,
                    filter: {
                        active: {
                            '$eq': true
                        }
                    }
                })
                this.formPeriods = jsonApiListParser(allIntervalPeriods, incPeriods)
            } catch (error) {
                this.$requestError(error.message)
            } finally {
                this.$root.$emit('loadingChange', false)
            }

        },
        async openDialogHandler(payload = {}) {
            const {
                mode = ASSIGNMENT,
                orgs = null,
                form = null,
                filters = null,
                cbAfterSave = null
            } = payload;
            this.listFilters = filters;
            console.log(this.listFilters);

            this.mode = mode
            this.organizationsFromEvent = orgs
            this.formFromEvent = form
            this.cbAfterSave = cbAfterSave
            if (orgs) {
                this.organizations = this.organizationsFromEvent.filter(org => !org.activeUntil || new Date(org.activeUntil) > new Date())
                this.formData.organizations = this.organizations.map(i => i.id)
                this.sendingType = this.sendingTypes.appendToAssignments
            } else {
                await this.fetchInitOrgs()
            }
            if (form) {
                this.forms = [form]
                this.selectedForm = form
            } else {
                await this.fetchInitForms()
            }
            this.showDialog = true;
        },
        debouncedFormFilter({ value }) {
            clearTimeout(this.filterTimeout)
            this.filterTimeout = setTimeout(() => {
                this.updateFormList(value)
            }, 500)
        },
        async updateFormList(val) {
            if (this.loading) return
            if (val) {
                if (val.length < 3) return
                try {
                    this.loading = true
                    this.$root.$emit('loadingChange', true)
                    const { forms, included } = await getAllForms({
                        filter: {
                            name: { '$like': val },
                            active: { '$eq': true }
                        },
                        relationShip: true
                    });
                    this.forms = jsonApiListParser(forms, included)
                    if (this.selectedForm && this.selectedForm.id) this.forms.push(this.selectedForm)
                } catch (error) {
                    this.$requestError(error.message);
                } finally {
                    this.loading = false
                    this.$root.$emit('loadingChange', false)
                }
            } else await this.fetchInitForms()
        },

        clearData() {
            this.selectedForm = {}
            this.formData = new SendForm()
            this.organizations = []
            this.forms = []
            this.currentPage = 1
            this.formPeriods = []
            this.cbAfterSave = null
            this.sendingType = this.sendingTypes.allAssignments
            this.formFromEvent = null
            this.organizationsFromEvent = null
        },
        async distributeSaveHandler() {
            if (this.disabledDialogSaveBtn) return
            try {
                this.$root.$emit('loadingChange', true)
                let dataForDistribute = {}
                if (!this.forOnlyAssignments) {
                    const orgs = this.formData.organizations.map(i => ({ id: i, type: 'organization' }))
                    const assignments = orgs.map(i => {
                        const { data } = new SubmitJsonApi({ organization: i, year: '' }, 'form-assignment');
                        return data
                    })
                    if (this.sendingType === this.sendingTypes.sendOnlySelected) dataForDistribute.organizations = { data: orgs }
                    await createFormAssignments(this.selectedForm.id, { data: assignments })
                }
                const periods = this.formData.periods.map(i => ({ id: i, type: 'period' }))
                dataForDistribute.periods = { data: periods }
                const { data: result } = await createNewFormsdistribute(this.selectedForm.id, {
                    data: dataForDistribute
                });
                this.$toast.add({ severity: 'success', detail: `Создано новых ${ result } раздач(и)`, life: 3000 });
                return true;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange', false)
            }
        },
        async assignmentSaveHandler() {
            this.$root.$emit('loadingChange', true);
            try {
                const orgs = this.formData.organizations.map(i => ({ id: i, type: 'organization' }))
                const assignments = orgs.map(i => {
                    const { data } = new SubmitJsonApi({ organization: i, year: '' }, 'form-assignment');
                    return data
                })
                await createFormAssignments(this.selectedForm.id, { data: assignments })
                return true
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange', false);
            }
        },
        async sendFormHandler() {
            const result = this.isDistributionMode ? await this.distributeSaveHandler() : await this.assignmentSaveHandler()
            if (this.cbAfterSave) this.cbAfterSave()
            if (result) this.showDialog = false
        },
    },
    watch: {
        showDialog: {
            handler(to) {
                if (!to) {
                    this.clearData()
                }
            }
        },
        'selectedForm.id': {
            handler(to) {
                if (to) this.fetchFormPeriods()
            }
        }
    },
};
</script>

<style lang='scss'>
.assignment-distribution-dialog {
    width: 960px!important;
    z-index: 5000!important;

    .p-multiselect-panel {
        width: 100%;
    }

    .p-field {

        .p-dropdown,
        .p-multiselect {
            &.p-disabled {
                background: #F5F5F5;
            }
        }

        display: flex;
        flex-direction: column;

        .checkbox-label {
            margin-bottom: 0;
            margin-left: 7px;
            color: #495058;
        }

        .p-dropdown-panel {
            width: 100%;
        }

        label {
            font-size: 13px;
            margin-bottom: 4px;
        }

        margin-bottom: 16px;

        &-combo {
            display: grid;
            grid-gap: 16px;

            &-2 {
                grid-template-columns: repeat(2, 1fr);
            }

            &-3 {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    .p-multiselect-token {
        background: var(--primary-color);
        height: 24px;
        width: 120px;
        padding: 4px 8px;
        border-radius: 3px;
        color: white;

        &-label {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .p-dialog {
        &-header {
            padding: 24px 24px 0 24px;
            font-size: 18px;
        }

        &-content {
            padding: 24px 24px 0 24px;
        }

        &-footer {
            padding: 24px 24px 24px 24px;
        }
    }

    .p-button {
        height: 34px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .radio-btns-container {
        margin-top: 4px;
        .p-field-radiobutton {
            margin-bottom: 5px!important;
        }
        label {
            margin-bottom: unset!important;
            line-height: 1rem;
        }
    }
}
</style>
