<template>
    <ul v-if="items" class="layout-menu" role="menu">
        <template v-for="(item, i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{ 'layout-root-menuitem': root, 'active-menuitem': activeIndexes.includes(i) && !item.disabled }]" role="menuitem">
                <router-link
                    v-if="checkMainMenuItemPermission(item)"
                    :to="item.to"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    active-class="active-route"
                    :target="item.target"
                    @click="onMenuItemClick($event, item, i)"
                    @mouseenter="onMenuItemMouseEnter(i)"
                    v-ripple
                >
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </router-link>
                <a
                    v-if="!item.to && checkSubMenuItems(item.label, item.items)"
                    :href="item.url || '#'"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                    :target="item.target"
                    @click="onMenuItemClick($event, item, i)"
                    @mouseenter="onMenuItemMouseEnter(i)"
                    v-ripple
                >
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </a>
                <div class="layout-root-menuitem" v-if="root">
                    <div class="layout-menuitem-root-text" style="text-transform: uppercase">{{ item.label }}</div>
                </div>
                <transition name="layout-menu">
                    <appsubmenu
                        v-if="showMenuItem(item, i)"
                        :items="visible(item) && item.items"
                        :menuActive="menuActive"
                        :layoutMode="layoutMode"
                        :parentMenuItemActive="activeIndexes.includes(i)"
                        @menuitem-click="$emit('menuitem-click', $event)"
                    ></appsubmenu>
                </transition>
            </li>
            <li class="menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
        </template>
    </ul>
</template>

<script>
import EventBus from './event-bus';
import { mapGetters } from 'vuex';
export default {
    name: 'appsubmenu',
    emits: ['root-menuitem-click', 'menuitem-click'],
    props: {
        items: Array,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
        root: {
            type: Boolean,
            default: false,
        },
        parentMenuItemActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('auth', [
            'userPermissionsObject'
        ])
    },
    data() {
        return {
            activeIndex: null,
            activeIndexes: []
        };
    },
    mounted() {
        EventBus.on('reset-active-index', () => {
            if (this.isSlim()) {
                this.activeIndex = null;
            }
        });
    },
    methods: {
        checkMainMenuItemPermission(item) {
            return item.to && (!item.permissionAccess?.length || item.permissionAccess.some(p => this.userPermissionsObject[p]))
        },
        checkSubMenuItems(label, items) {
            // Warning this is shitcode
            return label && items.some(item => (!item.permissionAccess?.length || item.permissionAccess.some(p => this.userPermissionsObject[p])))
        },
        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }
            //execute command
            // if (item.command) {
            //     item.command({ originalEvent: event, item: item });
            //     event.preventDefault();
            // }
            // if (item.items) {
            //     event.preventDefault();
            // }
            // if (this.root) {
            //     this.$emit('root-menuitem-click', {
            //         originalEvent: event,
            //     });
            // }
            if (item.items) {
                this.activeIndex = index === this.activeIndex ? null : index;
                const idx = this.activeIndexes.findIndex(i => i === index)
                if (~idx) this.activeIndexes.splice(idx, 1)
                else this.activeIndexes.push(index)
            }
            // this.$emit('menuitem-click', {
            //     originalEvent: event,
            //     item: item,
            // });
        },
        onMenuItemMouseEnter(index) {
            if (this.root && this.menuActive && this.layoutMode === 'slim' && !this.isMobile()) {
                this.activeIndex = index;
            }
        },
        visible(item) {
            return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        showMenuItem(item, rootIdx) {
            return item.items && (this.root && (!this.isSlim() || (this.isSlim() && (this.mobileMenuActive || this.activeIndexes.length))) ?
                true :
                this.activeIndexes.includes(rootIdx))
        }
    },
};
</script>
