<template>
    <div class="login-body">
        <Toast />
        <div class="login-wrapper">
            <div class="login-panel">
                <form @submit.prevent="onSubmit" :style="{ maxWidth: 270 + 'px' }" class="login-form">
                    <img class="p-mb-6" src="assets/layout/images/login-logo.svg" alt="diamond-layout" />
                    <div class="p-d-flex p-ai-center p-col-12 p-pl-0">
                        <h3 class="login_title p-text-center">Восстановление пароля</h3>
                    </div>
                    <div :class="inputGroupClasses">
                        <label class="color p-pb-1">Электронная почта</label>
                        <InputText id="email" name="email" v-model.trim="v$.email.$model" :class="v$.email.$invalid && submitted ? 'p-error' : ''" />
                        <small v-show="submitted" v-for="error in showErrors(v$.email)" :key="error.$uid" class="p-error">{{ error.$message }}</small>
                        <small class="p-error p-text-left" v-html="resetPasswordFailed" />
                    </div>
                    <div class="p-mb-0 p-pt-2">
                        <Button class="p-mb-4 p-button customPadding" label="Восстановить пароль" type="submit" />
                    </div>

                    <p class="p-text-left">
                        Есть сложность с восстановлением доступа? <br />
                        Обратитесь в службу поддержки НИИОЗМ <br />
                        по телефону: <a href="tel:84954171209" target="_blank" class="telColor">8 (495) 417-12-09 доб. 313/323</a>
                    </p>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-0 p-button-text decorateText p-py-1" @click="$router.push('/registrationUser')" label="Регистрация" />
                    </div>
                    <div class="p-mb-0 p-mr-auto">
                        <Button class="p-mr-2 p-mb-0 p-pl-0 p-button-text p-py-1" @click="$router.push('/login')">
                            <span class="p-button-label decorateText">Войти</span>
                        </Button>
                    </div>
                </form>
            </div>
            <LoginImage />
        </div>
    </div>
</template>

<script>
import LoginImage from '@/components/loginComponents/LoginImage';
import { requestToastHandler } from '@/main/mixins';
import { recoverPassword } from '@/api/auth';

import { required, email} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'passRecovery',
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data: () => ({
        email: '',
        inputGroupClasses: ['p-fluid', 'p-d-flex', 'p-flex-column', 'p-ai-start', 'p-col-12', 'p-pblank-0', 'p-pl-0', 'p-py-2'],
        error: '',
        submitted: false,
        resetPasswordFailed: ''
    }),
    validations() {
        return {
            email: {
                required: { ...required, $message: 'Поле обязательно для заполнения' },
                email: {
                    ...email,
                    $message: 'Введите корректный e-mail',
                },
            },
        };
    },
    components: { LoginImage },
    methods: {
        async onSubmit() {
            this.submitted = true;
            this.resetPasswordFailed = '';
            if (!this.v$.$invalid) {
                try {
                    await recoverPassword({
                        email: this.email,
                    });
                    await this.$router.push('/passRecoveryConfirm');
                } catch (error) {
                    this.resetPasswordFailed = error.message;
                }
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
};
</script>

<style lang="scss" scoped>
.login_title {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #4a4a4a;
    margin-bottom: 0;
}

.color {
    color: #4e4e4e;
}

.telColor {
    color: #606060 !important;
}

.decorateText {
    text-decoration: underline;
    font-size: 12px;
    line-height: 21px;
}

.customPadding {
    padding: 10px 17px;
}

.login-body .login-wrapper .login-panel p {
    font-weight: 400;

    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    color: #989898;
}
</style>
