<template>
    <div class="login-image p-jc-end">
        <div class="login-blackout"></div>
        <div class="login-image-content p-d-flex p-flex-column p-ai-start">
            <img src="assets/layout/images/inf-logo.svg" alt="diamond-layout" />
            <div class="maxWidth"><h2>Информационно-аналитическая система общественного здоровья</h2></div>

            <p class="p-mb-2">Служба технической поддержки:</p>
            <p class="p-mb-2">
                <a :href="`tel:${tel}`">{{ tel }}</a>
            </p>
            <p class="p-mb-2">
                <a :href="`mailto:${email}`">{{ email }}</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginImage',
    data: () => ({
        email: 'helpcms@zdrav.mos.ru',
        telHref: '+74954171209',
        tel: '8 (495) 417-12-09 доб. 313/323',
    }),
};
</script>

<style lang='scss' scoped>
.maxWidth {
    max-width: 575px;
}
</style>
