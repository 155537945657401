export const COLOR_PALETTE = [
    {
        label: 'бордовый',
        code: 'burgundy',
        background: 'dd7e6b',
        blocked: 'cc4125',
        calculated: 'e6b8af',
        font: '980000'
    },
    {
        label: 'красный',
        code: 'red',
        background: 'ea9999',
        blocked: 'e06666',
        calculated: 'f4cccc',
        font: 'ff0000'
    },
    {
        label: 'оранжевый',
        code: 'orange',
        background: 'f9cb9c',
        blocked: 'f6b26b',
        calculated: 'fce5cd',
        font: 'ff9900'
    },
    {
        label: 'жёлтый',
        code: 'yellow',
        background: 'ffe599',
        blocked: 'ffd966',
        calculated: 'fff2cc',
        font: 'ffff00'
    },
    {
        label: 'зелёный',
        code: 'green',
        background: 'b6d7a8',
        blocked: '93c47d',
        calculated: 'd9ead3',
        font: '00ff00'
    },
    {
        label: 'бирюзовый',
        code: 'turquoise',
        background: 'a2c4c9',
        blocked: '76a5af',
        calculated: 'd0e0e3',
        font: '00ffff'
    },
    {
        label: 'голубой',
        code: 'blue',
        background: 'a4c2f4',
        blocked: '6d9eeb',
        calculated: 'c9daf8',
        font: '4a86e8'
    },
    {
        label: 'синий',
        code: 'darkblue',
        background: '9fc5e8',
        blocked: '6fa8dc',
        calculated: 'cfe2f3',
        font: '0000ff'
    },
    {
        label: 'фиолетовый',
        code: 'violet',
        background: 'b4a7d6',
        blocked: '8e7cc3',
        calculated: 'd9d2e9',
        font: '9900ff'
    },
    {
        label: 'пурпурный',
        code: 'purple',
        background: 'd5a6bd',
        blocked: 'c27ba0',
        calculated: 'ead1dc',
        font: 'ff00ff'
    }
];

export const FORMS_FORM_TITLES = {
    EDIT: 'Редактировать форму',
    CREATE: 'Создать форму'
}

export const FORM_STATUSES_NAMES = {
    ARCHIVE: 'архивная',
    ACTIVE: 'активная',
    DRAFT: 'черновик'
}

export const FORM_STATUSES = Object.values(FORM_STATUSES_NAMES)

export const FORM_STATUSES_COLORS = {
    [FORM_STATUSES_NAMES.ACTIVE]: '#A1E6C3',
    [FORM_STATUSES_NAMES.ARCHIVE]: '#D7D7D7',
    [FORM_STATUSES_NAMES.DRAFT]: '#FFEDB3',
}

export const STATUSES_FORMS = [
    { value: 'archive', label: 'архивная', bgColor: '#A1E6C3' },
    { value: 'active', label: 'активная', bgColor: '#D7D7D7' },
    { value: 'draft', label: 'черновик', bgColor: '#FFEDB3' },
]

export const FORM_TYPES = {
    MONITORING: { value: 'monitoring', label: 'мониторинг' },
    FORM: { value: 'form', label: 'форма' }
}

export const FORM_TYPES_FILTERS = Object.values(FORM_TYPES).map(({ label }) => label)

export const FORM_FIELD_NAMES = {
    NAME: 'name',
    CODE: 'code',
    TYPE: 'type',
}

export const FORM_FIELDS = Object.values(FORM_FIELD_NAMES)

export const FORM_TEMPLATES_FIELD_NAMES = {
    NAME: 'name',
    // CODE: 'code',
    YEAR: 'year'
}

export const FORM_TEMPLATES_FIELDS = Object.values(FORM_TEMPLATES_FIELD_NAMES)

export const FORM_TEMPLATES_STATUS_NAMES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
}

export const FORM_TEMPLATES_STATUSES = [
    { value: FORM_TEMPLATES_STATUS_NAMES.ACTIVE, label: 'активный', bgColor: '#A1E6C3' },
    { value: FORM_TEMPLATES_STATUS_NAMES.INACTIVE, label: 'архивный', bgColor: '#D7D7D7' },
    // { value: 'new', label: 'новый', bgColor: '#FFBBB3' },
]

export const FORM_TEMPLATES_STATUSES_LABELS = Object.values(FORM_TEMPLATES_STATUSES).map(({ label }) => label)

export const FORM_TEMPLATES_DIALOG_MODES = {
    CREATE: {
        NAME: 'CREATE',
        TITLE: 'Создать шаблон'
    },
    COPY: {
        NAME: 'COPY',
        TITLE: 'Копировать шаблон'
    },
    EDIT: {
        NAME: 'EDIT',
        TITLE: 'Редактировать шаблон'
    }
}

export const REPORT_TEMPLATES_DIALOG_MODES = {
    CREATE: {
        NAME: 'CREATE',
        TITLE: 'Добавить шаблон отчета',
        BUTTON: 'Добавить'
    },
    EDIT: {
        NAME: 'EDIT',
        TITLE: 'Редактировать шаблон отчета',
        BUTTON: 'Сохранить'
    }
}

export const ASSIGNMENT_DISTRIBUTION_MODES = {
    DISTRIBUTION: 'DISTRIBUTION',
    ASSIGNMENT: 'ASSIGNMENT'
}

export const FORM_GROUP_DIALOG_MODES = {
    CREATE: {
        NAME: 'CREATE',
        TITLE: 'Добавить группу',
        BUTTON: 'Добавить'
    },
    EDIT: {
        NAME: 'EDIT',
        TITLE: 'Редактировать группу',
        BUTTON: 'Сохранить'
    }
};

export const WORKSHEET_DIALOG_MODES = {
    CREATE: {
        NAME: 'CREATE',
        TITLE: 'Добавить лист',
        BUTTON: 'Добавить'
    },
    EDIT: {
        NAME: 'EDIT',
        TITLE: 'Редактировать лист',
        BUTTON: 'Сохранить'
    }
};

export const VALUE_TYPES = [
    { value: 'int', label: 'Целое число' },
    { value: 'int_negative', label: 'Отрицательное целое число' },
    { value: 'float', label: 'Дробное число' },
    { value: 'float_negative', label: 'Отрицательное дробное число' },
    { value: 'float_quarter', label: 'Кратное 0,25' },
    { value: 'float_round1', label: '1 знак после плавающей запятой' },
    { value: 'float_round2', label: '2 знака после плавающей запятой' },
    { value: 'any', label: 'Произвольное значение' }
]

export const AGGREGATE_TYPES = [
    { value: 'sum', label: 'Сумма' },
    { value: 'avg', label: 'Среднее значение' },
    { value: 'latestPeriod', label: 'Последний период'}
]

export const CHECK_STATUSES = {
    from_cache: 'finish',
    clear_cache: 'clear',
    move_to_queue: 'поставлено в очередь',
    job_runnig: 'выполняется...',
    job_running: 'выполняется...',
    job_process: 'выполняется...',
    job_finish: 'finish'
}
