import { USER_MENU_PERMS } from '@/constants/common';

export default [
    {
        path: 'list',
        component: () => import('@/pages/control/notifications/notificationsList.vue'),
        meta: {
            permissionAccess: USER_MENU_PERMS.CONTROL,
        },
    }
];
