<template>
    <div class="layout-sidebar-right" :class="{ 'layout-sidebar-right-active': rightMenuActive }" @click="rightMenuClick">
        <!-- <user-notifications
      @closeUserNotifications="$emit('closeUserNotifications')"
    /> -->
    </div>
</template>

<script>
// import UserNotifications from '@/components/rightMenu/userNotifications';

export default {
    name: 'AppRightMenu',

    props: {
        rightMenuActive: Boolean,
    },
    data() {
        return {
            selectedAmount: '',
            amount: [
                {
                    label: '*****24',
                    value: { id: 1, name: '*****24', code: 'A1' },
                },
                {
                    label: '*****75',
                    value: { id: 2, name: '*****75', code: 'A2' },
                },
            ],
        };
    },
    mounted() {
        this.selectedAmount = this.amount[0];
    },
    methods: {
        rightMenuClick($event) {
            this.$emit('right-menu-click', $event);
        },
    },
};
</script>
