export const PAGE_SIZE = 5000;
export const DEFAULT_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 200;

export const USER_PERMISSIONS_MAP = {
    formReportAll: 'formReportAll',
    formReportOrg: 'formReportOrg',
    formReportOwn: 'formReportOwn',
    formApprove: 'formApprove',
    formRead: 'formRead',
    formReadOwn: 'formReadOwn',
    formRefresh: 'formRefresh',
    formRefreshOwn: 'formRefreshOwn',
    formReview: 'formReview',
    formVerificationRules: 'formVerificationRules',
    formWrite: 'formWrite',
    formWriteOwn: 'formWriteOwn',
    navAdmin: 'navAdmin',
    navFormAggregate: 'navFormAggregate',
    navFormEnter: 'navFormEnter',
    navForms: 'navForms',
    navOrganizations: 'navOrganizations',
    navUsers: 'navUsers',
    helpWrite: 'helpWrite',
    notificationWrite: 'notificationWrite',
    organizationRead: 'organizationRead',
    organizationReadAll: 'organizationReadAll',
    organizationReadOwn: 'organizationReadOwn',
    organizationWrite: 'organizationWrite',
    organizationWriteAll: 'organizationWriteAll',
    organizationWriteOwn: 'organizationWriteOwn',
    rbacRead: 'rbacRead',
    rbacWrite: 'rbacWrite',
    registerRead: 'registerRead',
    registerWrite: 'registerWrite',
    userImpersonate: 'userImpersonate',
    userReadAll: 'userReadAll',
    userWriteOwn: 'userWriteOwn',
    userWriteAll: 'userWriteAll',
    commentAdmin: 'commentAdmin'
}

const {
    formReportAll,
    formReportOrg,
    formReportOwn,
    navAdmin,
    navFormAggregate,
    navFormEnter,
    navForms,
    navOrganizations,
    navUsers,
    userReadAll,
    userWriteAll,
    userWriteOwn,
    registerRead,
    registerWrite
} = USER_PERMISSIONS_MAP

export const USER_MENU_PERMS = {
    FORMS: [ navForms ],
    CONTROL: [ navAdmin ],
    ORGANIZATIONS: [ navOrganizations ],
    USERS: {
        MAIN: [ navUsers ],
        LIST: [ userReadAll, userWriteAll, userWriteOwn ]
    },
    UPLOADING_REPORTS: [ formReportAll, formReportOrg, formReportOwn ],
    DATA_ENTRY: [ navFormEnter ],
    AGGREGATE: [ navFormAggregate ],
    REGISTERS: [ registerWrite, registerRead ],
    FAQ: []
}

export const SHOW_SEND_FORM_EVENT = 'show-send-form'

export const USER_FORM_PERMISSIONS = {
    write: {
        name: 'write',
        label: 'Заполнение'
    },
    approve: {
        name: 'approve',
        label: 'Утверждение'
    },
    review: {
        name: 'review',
        label: 'Проверка'
    },
    distribute: {
        name: 'distribute',
        label: 'Раздача'
    },
    read: {
        name: 'read',
        label: 'Просмотр'
    },
    report: {
        name: 'report',
        label: 'Отчеты'
    }
}

export const USER_FORM_PERMISSIONS_NAMES = Object.keys(USER_FORM_PERMISSIONS)

export const FORM_PERMISSIONS_MAP = {
    read: 'read',
    write: 'write',
    approve: 'approve',
    review: 'review',
    report: 'report',
    distribute: 'distribute'
}

export const SINGLE_LAYERS = ['aggregate', 'type'];
