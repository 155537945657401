import { apiUrl, axios } from '@/api/api.js';
import { fetchFormPermissions } from '@/api/form';
import { PAGE_SIZE } from '@/constants/common';
import { downloadFile, generateErrors, jsonApiListParser } from '@/main/utils/common';

export const me = async () => {
    try {
        const { data: { data, included } } = await axios.get(`${apiUrl}/user/me?include=role,organization,permissions`);
        const { attributes, id, relationships } = data;
        const userOrg = included.find(item => item.id === relationships.organization.data.id);
        const userPermissions = [...relationships.permissions.data];
        const canFormPermissions = userPermissions.filter((permission) => ['formReadOwn', 'formWriteOwn'].includes(permission.id));

        let formPermissions = {};
        if (canFormPermissions) {
            const { data: dataPermissions, included: includedPermissions } = await fetchFormPermissions(id);

            if (dataPermissions) {
                formPermissions = jsonApiListParser(dataPermissions, includedPermissions);
            }
        }

        return {
            id: id,
            email: attributes.email,
            firstName: attributes.firstName,
            lastName: attributes.lastName,
            secondName: attributes.patronymic,
            organization: userOrg?.attributes.publicName,
            userPermissions,
            formPermissions,
            orgId: relationships.organization.data.id,
            organizationObj: userOrg,
            role: included.find(item => item.type === 'role')
        };
    } catch (error) {
        generateErrors(error)
    }
};

export const getUserById = async userId => {
    try {
        const result = await axios.get(`${ apiUrl }/user/${ userId }`, {
            params: {
                include: 'organization,role,organization.parent,permissions'
            }
        });

        return {
            data: result.data.data,
            included: result.data.included,

        };
    } catch (error) {
        generateErrors(error);
    }
};

export const getUsers = async ({ page = 1, pageSize = PAGE_SIZE, sort = '', filter = {}, include = false }) => {
    try {
        const { data } = await axios.get(`${ apiUrl }/user`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                include: include ? 'organization,role' : '',
                sort,
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const getAllOrganizationUsers = async (id, pageNumber = 1, pageSize = PAGE_SIZE) => {

    try {
        const result = await axios.get(`${apiUrl}/user?filter={"organizationId":"${id}"}&include=organization,role&page=${pageNumber}&page-size=${pageSize}`);

        return {
            users: result.data.data,
            included: result.data.included,
            meta: result.data.meta,
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const getUsersPermissionsOnForm = async (formId, filter = {}, page = 1, pageSize = PAGE_SIZE) => {
    try {
        return await axios.get(`${ apiUrl }/form/${ formId }/relationships/users`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                include: 'organization,role'
            }
        });
    } catch (error) {
        generateErrors(error)
    }
};

export const registerNewUser = async data => {
    try {
        return await axios.post(`${ apiUrl }/user`, data);
    } catch (error) {
        generateErrors(error);
    }
};

export const patchUser = async (data, id) => {
    try {
        return await axios.patch(`${ apiUrl }/user/${ id }`, data);
    } catch (error) {
        generateErrors(error);
    }
};

export const getUserRightsByForm = async (userId) => {
    try {
        let result = await axios.get(`${ apiUrl }/user/${ userId }/relationships/forms`, {
            include: 'user,form',
            'page-size': PAGE_SIZE
        });

        return {
            data: result.data.data,
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const createUserFormRights = async (userId, dataToServer) => {
    try {
        return await axios.post(`${ apiUrl }/user/${ userId }/relationships/forms`, dataToServer);
    } catch (error) {
        generateErrors(error);
    }
};

export const deleteUserFormRights = async (userId, dataToServer) => {
    try {
        let result = await axios.delete(`${ apiUrl }/user/${ userId }/relationships/forms`, dataToServer);
        return result;
    } catch (error) {
        generateErrors(error);
    }
};


export const deleteUser = async id => {
    try {
        return await axios.delete(`${ apiUrl }/user/${ id }`);
    } catch (error) {
        generateErrors(error);
    }
};


export async function downloadUsers({ filter = {} }) {
    try {
        const result = await axios.get(`${ apiUrl }/user/download/xlsx`, {
            params: {
                page: 1,
                'page-size': PAGE_SIZE,
                filter: filter,
                include: 'organization,role'
            },
            responseType: 'blob'
        });

        downloadFile('users', result.data);
    } catch (error) {
        generateErrors(error);
    }
}

export const resetUserPassword = async userId => {
    try {
        return await axios.post(`${ apiUrl }/user/${ userId }/reset-password`);
    } catch (error) {
        generateErrors(error);
    }
};
