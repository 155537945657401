export function date (value) {
  if (!value) {return '';}
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  return new Date(value).toLocaleDateString('ru-RU', options);
}

export function time (value) {
  if (!value) {return '';}
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(value).toLocaleTimeString('ru-RU', options);
}

export function dateTime(value) {
    if (!value) {
        return '';
    }

    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    return new Date(value).toLocaleDateString('ru-RU', options).replace(',', ' ');
}

export function monthYear (value) {
  if (!value) {return '';}
  const options = {
    year: 'numeric',
    month: 'long',
  };
  return new Date(value).toLocaleDateString('ru-RU', options);
}
