import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export const getAllFormsPeriods = async (page = 1, pageSize = PAGE_SIZE, sortParametr = '') => {
    try {
        const { data: { data, meta } } = await axios.get(`${ apiUrl }/form-distribution-interval`, {
            params: {
                page,
                'page-size': pageSize,
                sort: sortParametr
            }
        });

        return {
            allPeriods: [...data],
            included: data,
            meta: meta
        }
    } catch (error) {
        generateErrors(error);
    }
};

export const getAllIntervalPeriods = async ({ intervalId, page = 1, pageSize = PAGE_SIZE, sort = '-activeFrom', filter, include = false }) => {
    try {
        const { data } = await axios.get(`${ apiUrl }/form-distribution-interval/${ intervalId }/relationships/periods`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter),
                sort: sort,
                include: include ? 'interval' : ''
            }
        })

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const getFormsPeriodById = async id => {
    try {
        const result = await axios.get(`${apiUrl}/form-distribution-interval/${id}`);
        return {
            data: result.data.data,
            included: [],
        }
    } catch (error) {
        generateErrors(error);
    }
};

export const registerNewFormsPeriod = async data => {
    try {
        const response = await axios.post(`${apiUrl}/form-distribution-interval`, data);

        return response
    } catch (error) {
        generateErrors(error);
    }
};

export const generatePeriodIntervals = async (intervalId, data) => {
    try {
        const response = await axios.post(`${apiUrl}/form-distribution-interval/${intervalId}/generate-periods`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const patchFormsPeriod = async (id, data) => {
    try {
        return await axios.patch(`${apiUrl}/form-distribution-interval/${id}`, data)
    } catch (error) {
        generateErrors(error);
    }
};

export const deleteFormsPeriod = async id => {
    try {
        const response = await axios.delete(`${apiUrl}/form-distribution-interval/${id}`);

        return response;
    } catch (error) {
        generateErrors(error);
    }
};

export const getPeriods = async ({ page = 1, pageSize = PAGE_SIZE, filter = {} }) => {
    try {
        return await axios.get(`${ apiUrl }/form-distribution-period`, {
            params: {
                page,
                'page-size': pageSize,
                filter: JSON.stringify(filter)
            }
        })
    } catch (error) {
        generateErrors(error);
    }
};
