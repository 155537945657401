<template>
    <div class="layout-sidebar" @click="onMenuClick">
        <router-link to="/" class="logo">
            <img id="app-logo" src="@/assets/img/logo.svg" alt="logo" />
        </router-link>

        <div class="layout-menu-container">
            <AppSubmenu
                :items="model"
                :layoutMode="layoutMode"
                :parentMenuItemActive="true"
                :menuActive="active"
                :mobileMenuActive="mobileMenuActive"
                :root="true"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />
        </div>
    </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    props: {
        model: Array,
        layoutMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: { AppSubmenu },
};
</script>

<style lang="scss">
.layout-sidebar-niioz .layout-sidebar .logo {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
}

a:hover {
    text-decoration: none;
}

.layout-sidebar .logo {
    margin-left: 1.65rem;
    margin-right: 1.65rem;
    padding-top: 1.65rem;
    padding-bottom: 1.65rem;
}

.layout-menu-container {
    padding: 1.65rem;
}

.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
    padding: 0;
}
</style>
