<template>
    <div ref="container" :class="containerClass" @click="onClick">
        <div class="p-hidden-accessible">
            <input ref="focusInput" type="text" role="listbox" :id="inputId" readonly :disabled="disabled" @focus="onFocus" @blur="onBlur" @keydown="onKeyDown" :tabindex="tabindex"
                   aria-haspopup="listbox" :aria-expanded="overlayVisible" :aria-labelledby="ariaLabelledBy"/>
        </div>
        <div class="p-multiselect-label-container">
            <div
                class='p-multiselect-label'
            >
                <slot name="value" :value="value" :placeholder="placeholder">
                    <template v-if="display === 'comma'">
                        {{label || 'empty'}}
                    </template>
                    <template v-else-if="display === 'chip'">
                        <template v-for="(item, index) of value">
                            <div v-if='getLabelByValue(item)' class="p-multiselect-token" :key="getLabelByValue(item) + 'chip' + index">
                                <span :title='getLabelByValue(item)' class="p-multiselect-token-label">{{getLabelByValue(item)}}</span>
                                <span v-if="!disabled" class="p-multiselect-token-icon" @click.stop="removeChip(item)">
                                    <i class='pi pi-times'></i>
                                </span>
                            </div>
                        </template>
                        <template v-if="!value || value.length === 0">
                            <div class='placeholder'>
                                {{placeholder || 'empty'}}
                            </div>
                        </template>
                    </template>
                    <template v-else-if='display === "chip-count"'>
                        <template v-if='value && value.length > 1' class='p-multiselect-token p-d-flex p-jc-between'>
                            <div class="p-multiselect-token">
                                <span class="p-multiselect-token-label">Выбрано: {{ value.length }}</span>
                                <span v-if="!disabled" class="p-multiselect-token-icon" @click.stop="removeChip(item)">
                                    <i class='pi pi-times'></i>
                                </span>
                            </div>
                        </template>
                        <template v-else class='p-multiselect-token p-d-flex p-jc-between'>
                            <template v-for="(item, index) of value">
                                <div class="p-multiselect-token" :key="getLabelByValue(item) + 'chip' + index">
                                    <span :title='getLabelByValue(item)' class="p-multiselect-token-label">{{ getLabelByValue(item) }}</span>
                                    <span v-if="!disabled" class="p-multiselect-token-icon" @click.stop="removeChip(item)">
                                        <i class='pi pi-times'></i>
                                    </span>
                                </div>
                            </template>
                        </template>
                        <template v-if="!value || value.length === 0">
                            <div class='placeholder'>
                                {{ placeholder || 'empty' }}
                            </div>
                        </template>
                    </template>
                </slot>
            </div>
        </div>
        <div class="p-multiselect-trigger">
            <slot name="indicator">
                <span class="p-multiselect-trigger-icon pi pi-chevron-down"></span>
            </slot>
        </div>
        <div
            name="p-connected-overlay"
            :class='{"p-multiselect-overlay-table": dataTableMode}'
            @click.stop v-click-outside='outsideClick'
            @enter="onOverlayEnter"
            @leave="onOverlayLeave"
        >
            <template v-if='overlayVisible'>
                <DataTable
                    v-if='dataTableMode'
                    :value="visibleOptions"
                    :paginator="true"
                    class="p-datatable-customers p-datatable-striped form-list"
                    :rows="20"
                    :rowsPerPageOptions="rowsPerPageOptions"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate=""
                    :totalRecords="visibleOptions.length"
                    :page-link-size='10'
                    dataKey="id"
                    :filters="filters"
                    :scrollable="true"
                    scroll-height="200px"
                    resizable-columns
                    :selection.sync='selectedItems'
                >
                    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                    <Column header='' :field='dataTableField'>
                    </Column>
                </DataTable>
                <div v-else ref="overlay" class="p-multiselect-panel p-component">
                    <div class="p-multiselect-header" v-if="(showToggleAll && selectionLimit == null) || filter">
                        <div class="p-checkbox p-component" v-if="showToggleAll && selectionLimit == null" @click="onToggleAll" role="checkbox" :aria-checked="allSelected">
                            <div class="p-hidden-accessible">
                                <input type="checkbox" readonly @focus="onHeaderCheckboxFocus" @blur="onHeaderCheckboxBlur">
                            </div>
                            <div :class="['p-checkbox-box', {'p-highlight': allSelected, 'p-focus': headerCheckboxFocused}]" role="checkbox" :aria-checked="allSelected">
                                <span :class="['p-checkbox-icon', {'pi pi-check': allSelected}]"></span>
                            </div>
                        </div>
                        <div v-if="filter" class="p-multiselect-filter-container">
                            <input type="text" ref="filterInput" v-model="filterValue" class="p-multiselect-filter p-inputtext p-component" :placeholder="filterPlaceholder" @input="onFilterChange">
                            <span class="p-multiselect-filter-icon pi pi-search"></span>
                        </div>
                        <button class="p-multiselect-close p-link" @click="onCloseClick" type="button" v-ripple>
                            <span class="p-multiselect-close-icon pi pi-times" />
                        </button>
                    </div>
                    <div ref="itemsWrapper" class="p-multiselect-items-wrapper" :style="{'max-height': scrollHeight}">
                        <ul class="p-multiselect-items p-component" role="listbox" aria-multiselectable="true">
                            <li v-for="(option, i) of visibleOptions" :class="['p-multiselect-item', {'p-highlight': isSelected(option), 'p-disabled': isOptionDisabled(option)}]" role="option" :aria-selected="isSelected(option)"
                                :aria-label="getOptionLabel(option)" :title="getOptionLabel(option)" :key="getOptionRenderKey(option) + i + 'option'" @click="onOptionSelect($event, option)" @keydown="onOptionKeyDown($event, option)" :tabindex="tabindex||'0'" v-ripple>
                                <div class="p-checkbox p-component">
                                    <div :class="['p-checkbox-box', {'p-highlight': isSelected(option)}]">
                                        <span :class="['p-checkbox-icon', {'pi pi-check': isSelected(option)}]"></span>
                                    </div>
                                </div>
                                <slot name="option" :option="option" :index="i">
                                    <span>{{getOptionLabel(option)}}</span>
                                </slot>
                            </li>
                            <li v-if="filterValue && (!visibleOptions || (visibleOptions && visibleOptions.length === 0))" class="p-multiselect-empty-message">{{emptyFilterMessage}}</li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ConnectedOverlayScrollHandler from 'primevue/components/utils/ConnectedOverlayScrollHandler';
import ObjectUtils from 'primevue/components/utils/ObjectUtils';
import DomHandler from 'primevue/components/utils/DomHandler.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        serverFilter: { type: Boolean, default: false },
        value: null,
        options: Array,
        optionLabel: null,
        optionValue: null,
        optionDisabled: null,
        scrollHeight: {
            type: String,
            default: '200px'
        },
        placeholder: String,
        disabled: Boolean,
        filter: Boolean,
        tabindex: String,
        inputId: String,
        dataKey: null,
        filterPlaceholder: String,
        filterLocale: String,
        ariaLabelledBy: null,
        appendTo: {
            type: String,
            default: null
        },
        emptyFilterMessage: {
            type: String,
            default: 'No results found'
        },
        display: {
            type: String,
            default: 'comma'
        },
        selectionLimit: {
            type: Number,
            default: null
        },
        showToggleAll: {
            type: Boolean,
            default: true
        },
        itemsForSelect: Array,
        dataTableMode: { type: Boolean, default: false },
        dataTableField: String
    },
    data() {
        return {
            focused: false,
            headerCheckboxFocused: false,
            filterValue: '',
            overlayVisible: false,
            filters: {},
            selectedItems: []
        };
    },
    outsideClickListener: null,
    resizeListener: null,
    scrollHandler: null,
    mounted() {
        this.selectedItems = this.itemsForSelect
    },
    updated() {
        this.onFilterUpdated();
    },
    beforeDestroy() {
        this.restoreAppend();
        this.unbindOutsideClickListener();
        this.unbindResizeListener();

        if (this.scrollHandler) {
            this.scrollHandler.destroy();
            this.scrollHandler = null;
        }
    },
    methods: {
        onScrollLabelHandler(e) {
            const { target } = e
            const { clientHeight, scrollTop, scrollHeight } = target
            if (scrollHeight < clientHeight + scrollTop + 1) this.$emit('labelScrollEnd')
        },
        outsideClick() {
            if (this.overlayVisible) this.hide()
        },
        getOptionLabel(option = '') {
            return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option;
        },
        getOptionValue(option) {
            return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option;
        },
        getOptionRenderKey(option) {
            return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option);
        },
        isOptionDisabled(option) {
            if (this.maxSelectionLimitReached && !this.isSelected(option)) {
                return true;
            }
            return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false;
        },
        isSelected(option) {
            let selected = false;
            let optionValue = this.getOptionValue(option);

            if (this.value) {
                for (let val of this.value) {
                    if (ObjectUtils.equals(val, optionValue, this.equalityKey)) {
                        selected = true;
                        break;
                    }
                }
            }

            return selected;
        },
        show() {
            // Задержка для того, чтоьбы сработала логика outsideClick
            setTimeout(() => {
                this.$emit('before-show');
                this.overlayVisible = true;
            }, 100)
        },
        hide() {
            this.$emit('before-hide');
            this.overlayVisible = false;
        },
        onFocus() {
            this.focused = true;
        },
        onBlur() {
            this.focused = false;
        },
        onHeaderCheckboxFocus() {
            this.headerCheckboxFocused = true;
        },
        onHeaderCheckboxBlur() {
            this.headerCheckboxFocused = false;
        },
        onClick() {
            if (!this.disabled && (!this.$refs.overlay || !this.$refs.overlay.contains(event.target)) && !DomHandler.hasClass(event.target, 'p-multiselect-close')) {
                if (this.overlayVisible)
                    this.hide();
                else
                    this.show();

                this.$refs.focusInput.focus();
            }
        },
        onCloseClick() {
            this.hide();
        },
        onKeyDown(event) {
            switch(event.which) {
                //down
                case 40:
                    if (this.visibleOptions && !this.overlayVisible && event.altKey) {
                        this.show();
                    }
                    break;

                //space
                case 32:
                    if (!this.overlayVisible) {
                        this.show();
                        event.preventDefault();
                    }
                    break;

                //enter and escape
                case 13:
                case 27:
                    if (this.overlayVisible) {
                        this.hide();
                        event.preventDefault();
                    }
                    break;

                //tab
                case 9:
                    this.hide();
                    break;

                default:
                    break;
            }
        },
        onOptionSelect(event, option) {
            if (this.disabled || this.isOptionDisabled(option)) {
                return;
            }

            let selected = this.isSelected(option);
            let value = null;

            if (selected)
                value = this.value.filter(val => !ObjectUtils.equals(val, this.getOptionValue(option), this.equalityKey));
            else
                value = [...this.value || [], this.getOptionValue(option)];

            this.$emit('input', value);
            this.$emit('change', {originalEvent: event, value: value});
        },
        onOptionKeyDown(event, option) {
            let listItem = event.target;

            switch(event.which) {
                //down
                case 40:
                    var nextItem = this.findNextItem(listItem);
                    if (nextItem) {
                        nextItem.focus();
                    }

                    event.preventDefault();
                    break;

                //up
                case 38:
                    var prevItem = this.findPrevItem(listItem);
                    if (prevItem) {
                        prevItem.focus();
                    }

                    event.preventDefault();
                    break;

                //enter
                case 13:
                    this.onOptionSelect(event, option);
                    event.preventDefault();
                    break;

                default:
                    break;
            }
        },
        findNextItem(item) {
            let nextItem = item.nextElementSibling;

            if (nextItem)
                return DomHandler.hasClass(nextItem, 'p-disabled') ? this.findNextItem(nextItem) : nextItem;
            else
                return null;
        },
        findPrevItem(item) {
            let prevItem = item.previousElementSibling;

            if (prevItem)
                return DomHandler.hasClass(prevItem, 'p-disabled') ? this.findPrevItem(prevItem) : prevItem;
            else
                return null;
        },
        onOverlayEnter() {
            this.$refs.overlay.style.zIndex = String(DomHandler.generateZIndex());
            this.appendContainer();
            this.alignOverlay();
            this.bindOutsideClickListener();
            this.bindScrollListener();
            this.bindResizeListener();
            this.$emit('show');

            if (this.filter) {
                this.$refs.filterInput.focus();
            }
        },
        onOverlayLeave() {
            this.unbindOutsideClickListener();
            this.unbindScrollListener();
            this.unbindResizeListener();
            this.$emit('hide');
        },
        alignOverlay() {
            if (this.appendTo) {
                DomHandler.absolutePosition(this.$refs.overlay, this.$refs.container);
                this.$refs.overlay.style.minWidth = DomHandler.getOuterWidth(this.$refs.container) + 'px';
            }
            else {
                DomHandler.relativePosition(this.$refs.overlay, this.$refs.container);
            }
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.overlayVisible && this.isOutsideClicked(event)) {
                        this.hide();
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        bindScrollListener() {
            if (!this.scrollHandler) {
                this.scrollHandler = new ConnectedOverlayScrollHandler(this.$el, () => {
                    if (this.overlayVisible) {
                        this.hide();
                    }
                });
            }

            this.scrollHandler.bindScrollListener();
        },
        unbindScrollListener() {
            if (this.scrollHandler) {
                this.scrollHandler.unbindScrollListener();
            }
        },
        bindResizeListener() {
            if (!this.resizeListener) {
                this.resizeListener = () => {
                    if (this.overlayVisible && !DomHandler.isAndroid()) {
                        this.hide();
                    }
                };
                window.addEventListener('resize', this.resizeListener);
            }
        },
        unbindResizeListener() {
            if (this.resizeListener) {
                window.removeEventListener('resize', this.resizeListener);
                this.resizeListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$refs.container.isSameNode(event.target) || this.$refs.container.contains(event.target) || (this.$refs.overlay && this.$refs.overlay.contains(event.target)));
        },
        getLabelByValue(val) {
            let label = null;

            if (this.options) {
                for (let option of this.options) {
                    let optionValue = this.getOptionValue(option);

                    if(ObjectUtils.equals(optionValue, val, this.equalityKey)) {
                        label = this.getOptionLabel(option);
                        break;
                    }
                }
            }

            return label;
        },
        onToggleAll(event) {
            const value = this.allSelected ? [] : this.visibleOptions  && this.visibleOptions.map(option => this.getOptionValue(option));

            this.$emit('input', value);
            this.$emit('change', {originalEvent: event, value: value});
        },
        appendContainer() {
            if (this.appendTo) {
                if (this.appendTo === 'body')
                    document.body.appendChild(this.$refs.overlay);
                else
                    document.getElementById(this.appendTo).appendChild(this.$refs.overlay);
            }
        },
        restoreAppend() {
            if (this.$refs.overlay && this.appendTo) {
                if (this.appendTo === 'body')
                    document.body.removeChild(this.$refs.overlay);
                else
                    document.getElementById(this.appendTo).removeChild(this.$refs.overlay);
            }
        },
        onFilterChange(event) {
            this.$emit('filter', {originalEvent: event, value: event.target.value});
        },
        onFilterUpdated() {
            if (this.overlayVisible) {
                this.alignOverlay();
            }
        },
        removeChip(item) {
            let value;
            if (this.display === 'chip-count') {
                value = [];
            } else {
                value = this.value.filter(val => !ObjectUtils.equals(val, item, this.equalityKey));
            }

            this.$emit('input', value);
            this.$emit('change', {originalEvent: event, value: value});
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        // selectedItems: {
        //     get() { return this.value },
        //     set(v) { this.$emit('input', v) }
        // },
        visibleOptions() {
            if (this.serverFilter) return this.options
            if (this.filterValue && this.filterValue.trim().length > 0)
                return this.options.filter(option => {
                    if (!this.getOptionLabel(option)) return false
                    return this.getOptionLabel(option).toLocaleLowerCase(this.filterLocale).indexOf(this.filterValue.toLocaleLowerCase(this.filterLocale)) > -1;
                });
            else
                return this.options;
        },
        containerClass() {
            return [
                'p-multiselect p-component p-inputwrapper',
                {
                    'p-multiselect-chip': this.display === 'chip',
                    'p-disabled': this.disabled,
                    'p-focus': this.focused,
                    'p-inputwrapper-filled': this.value && this.value.length,
                    'p-inputwrapper-focus': this.focused || this.overlayVisible
                }
            ];
        },
        labelClass() {
            return [
                'p-multiselect-label',
                {
                    'p-placeholder': this.label === this.placeholder,
                    'p-multiselect-label-empty': !this.placeholder && (!this.value || this.value.length === 0)
                }
            ];
        },
        label() {
            let label;

            if (this.value && this.value.length) {
                label = '';
                for(let i = 0; i < this.value.length; i++) {
                    if(i !== 0) {
                        label += ', ';
                    }

                    label += this.getLabelByValue(this.value[i]);
                }
            }
            else {
                label = this.placeholder;
            }

            return label;
        },
        allSelected() {
            if (this.filterValue && this.filterValue.trim().length > 0) {
                let allSelected = true;
                if(this.visibleOptions.length > 0) {
                    for (let option of this.visibleOptions) {
                        if (!this.isSelected(option)) {
                            allSelected = false;
                            break;
                        }
                    }
                }
                else
                    allSelected = false;
                return allSelected;
            }
            else {
                return this.value && this.options && (this.value.length > 0 && this.value.length === this.options.length);
            }
        },
        equalityKey() {
            return this.optionValue ? null : this.dataKey;
        },
        maxSelectionLimitReached() {
            return this.selectionLimit && (this.value && this.value.length === this.selectionLimit);
        }
    },
    watch: {
        selectedItems(to) {
            this.$emit('update:items-for-select', to)
        }
    }
}
</script>

<style lang='scss'>
.p-multiselect {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    user-select: none;

    &-panel {
        z-index: 999;
    }

    .p-multiselect-label  {
        //display: block;
        white-space: nowrap;
        cursor: pointer;
        overflow-y: auto;
        text-overflow: ellipsis;
        display: grid;
        max-height: 184px;
        padding: 5px 6px;
        grid-gap: 4px;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        width: 100%;
    }
}

.p-multiselect-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.p-multiselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
    min-height: 34px;
    display: flex;
    align-items: center;
}

.p-multiselect-label-empty {
    overflow: hidden;
    visibility: hidden;
}

.p-multiselect-token {
    cursor: default;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
}

.p-multiselect-token-icon {
    cursor: pointer;
    i {
        font-size: 8px;
    }
}

.p-multiselect .p-multiselect-panel {
    min-width: 100%;
}

.p-multiselect-panel {
    position: absolute;
}

.p-multiselect-items-wrapper {
    overflow: auto;
}

.p-multiselect-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.p-multiselect-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}

.p-multiselect-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-multiselect-filter-container {
    position: relative;
    flex: 1 1 auto;
}

.p-multiselect-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

.p-multiselect-filter-container .p-inputtext {
    width: 100%;
}

.p-multiselect-close {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    margin-left: auto;
}

.p-fluid .p-multiselect {
    display: flex;
}

.p-multiselect-overlay-table {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1000;
}
</style>
