import { apiUrl, axios } from '@/api/api.js'
import { getRefreshTokenId, getStoreAuth } from './authData';
import jwtDecode from "jwt-decode";
//import router from '@/router';
// export const apiUrl = process.env.VUE_APP_API_URL;

export async function refresh() {
    const refreshTokenId = getRefreshTokenId();
    /* eslint-disable */
    try {
        const result = await axios.post(`${ apiUrl }/auth/refresh`, {
            refreshTokenId,
            fingerPrint: 'string',
          },
          { withCredentials: true }
        );

        const accessData = result.data.data;

        return accessData ? accessData : false;
    } catch (error) {
        throw error;
    }
}

export async function loginUser(data) {
  const authData = {
    ...data,
    fingerPrint: 'string',
  };
    // eslint-disable-next-line no-useless-catch
  try {
    const result = await axios.post(`${ apiUrl }/auth/login`, authData);
    const accessData = result.data.data;

    if (accessData) {
      return accessData.attributes;
    }

  } catch (error) {
    throw error;
  }
}

export async function autorizationAnotherUser(userId) {
  try {
    /* eslint-disable */
    const result = await axios.post(`${apiUrl}/auth/impersonate/${userId}`, {
      fingerPrint: 'string',
    });

    return result.data.data
  } catch (error) {
    let title = error.response.data.errors[0].title;
    switch (title) {
      case 'Credentials invalid.':
        throw new Error('Неверные имя пользователя или пароль');
      case 'User not verified.':
        throw new Error('Пользователь не подтвержден');
      default:
        if (title.indexOf('not found') >= 0) throw new Error('Пользователь не найден');
        else {
          throw new Error('Неизвестная ошибка');
        }
    }
  }
}

export function startRefreshTimer(tokenData) {
    if (localStorage.getItem('refreshTimer') == true) {
        return;
    }

    const accessTokenInfo = jwtDecode(tokenData.accessToken);
    const { exp } = accessTokenInfo;
    let expTime = new Date(parseFloat(exp) * 1000);
    let stopTime = expTime.setSeconds(expTime.getSeconds() - 30);
    console.log('expTime', expTime);

    let countdown = setInterval(function() {
        let now = new Date().getTime();
        let remain = stopTime - now;

        let min = Math.floor( (remain % (1000 * 60 * 60)) / (1000 * 60) );
        let sec = Math.floor( (remain % (1000 * 60)) / 1000 );
        sec = sec < 10 ? '0' + sec : sec;
        console.log(`${min}:${sec}`);

        if (remain < 0) {
            clearInterval(countdown);
            localStorage.setItem('refreshTimer', 'false');
            getStoreAuth().dispatch('AccessTokenRefresh');
        }
    }, 1000);

    localStorage.setItem('refreshTimer', 'true');
}

export async function recoverPassword(data) {
  try {
    await axios.post(`${apiUrl}/user/reset-password`, data);
    return true;
  } catch (error) {
      const {response: {data: {errors} }} = error
      if (error.isAxiosError) {
          let lastError = errors[errors.length - 1];
          throw new Error(lastError.detail);
      }
  }
}

export async function changePassword(data) {
    try {
        await axios.post(`${apiUrl}/auth/change-password`, data);
        return true;
    } catch (error) {
        const {response: {data: {errors} }} = error
        if (error.isAxiosError) {
            let lastError = errors[errors.length - 1]
            throw new Error(lastError.title);
        }
    }
}
